import React, { useEffect, useState } from 'react'
import Layout from './Layout'
import styled from '@emotion/styled';
import { Box, Button, Input, MenuItem, Select, TextField, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import VideocamIcon from '@mui/icons-material/Videocam';
import CreateIcon from '@mui/icons-material/Create';
import LinkIcon from '@mui/icons-material/Link';
import { createContent, deleteSingleProduct, getContent } from '../Service/api';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import DOMPurify from 'dompurify';

const Heading = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 20px;
`;


const MobHeading = styled(Box)`
  display: flex;
  height: 20px;
  justify-content: center;
  flex-direction: column;
  align-items: start;
`;

const MainHeading = styled(Box)`
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding: 1%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: start;
  height: 7vh;
`

const EditHeading = styled(Box)`
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding: 1%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: start;
  height: 7vh;
  @media (max-width: 767px) {
    height: 10vh;
  }
`



const MainContent = styled(Box)`
  border: 1px solid rgba(0, 0, 0, 0.2);
  margin-top: 1vh;
  width: 59%;
  @media (max-width: 767px) {
    margin-top: 1vh;
    padding: 1vh;
    width: 100%;
  }
`;

const ViewContent = styled(Box)`
  border: 1px solid rgba(0, 0, 0, 0.2);
  margin-top: 1vh;
  width: 40%;

  @media (max-width: 767px) {
    margin-top: 1vh;
    padding: 10px; /* Adjust padding for better mobile layout */
    width: 100%;
  }
`;


const MainBox = styled(Box)`
  width: auto;
  display: flex;
  justify-content: space-between;
`

const MobileBox = styled(Box)`
  display: flex;
  flex-direction: column;
`


const ArabicWrapper = styled.div`
  direction: rtl;
`;

const ArabicHeading = styled.h4`
  font-family: 'Arabic Font', Arial, sans-serif;
  text-align: right;
`;

const EnglishHeading = styled.h4`
  display: flex;
  justify-content: start;
`;

const RichTextEditorWrapper = styled(Box)`
  padding: 2%;
  .ql-container {
    border: 1px solid #ccc;
    min-height: 200px;
    text-align: right; /* Align the Arabic text inside the editor to the right */
  }
`;

const Btn = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: start;
  margin-top: 1vh;
  margin-bottom: 1vh;

  @media (max-width: 767px) {
    margin-top: 2vh;
    margin-bottom: 2vh;
  }
`;

const UL = styled.ul`
  margin: 0;
  padding: 0;
  margin-left: 1%;
`;

const EditContent = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 1%;
  margin-left: 1%;
  padding-left: 0;
  margin-left: 0;
  height: 5vh;
  background-color: #e9e9ef40;
  border-top: 1px solid rgba(0, 0, 0, 0.2);

  &:nth-of-type(2) {
    background-color: white;
  }

  @media (max-width: 767px) {
    height: auto;
    padding: 5px;
    margin-bottom: 5px;
  }
`;


const EditContentHeading = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 2%;
  padding-right: 2%;
  min-height: 2vh;
`;

const Written = styled(Box)`
  min-height: 1.5vh;
  word-wrap: break-word;
  overflow: hidden;
  width: 10vw;
  display: flex;
  justify-content: start;
`;

const Delete = styled(Box)`
  height: 28px;
  width: 2vw;
  border: 1px solid rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #74788D;
  cursor: pointer;

  @media (max-width: 767px) {
    height: 30px;
    width: 30px;
    font-size: 18px;
  }
`;

const ArabicTextField = styled(TextField)`
  direction: rtl;
`;

const ScrollableContainer = styled.div`
  /* Enable horizontal scroll for mobile screens */
  overflow-x: auto;

  /* Hide horizontal scrollbar */
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`;

const MainHeadingWrapper = styled.div`
  /* Enable horizontal scroll for mobile screens */
  overflow-x: auto;

  /* Hide horizontal scrollbar */
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`;

const Item = styled(Box)`
  padding: 0 10px;
  white-space: nowrap; /* Prevent item text from wrapping */
`;


const Content = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const [category, setCategory] = useState('');
  const [name, setName] = useState('');
  const [arname, setArname] = useState('');
  const [englishDescription, setEnglishDescription] = useState('');
  const [arabicDescription, setArabicDescription] = useState('');
  const [price, setPrice] = useState('');
  const [arabicPrice, setArabicPrice] = useState('');
  const [discountType, setDiscountType] = useState('Select Discount Type If Any');
  const [discountValue, setDiscountValue] = useState('');
  const [image, setImage] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [content, setContent] = useState([]);
  let categoryMatchFound = false;

  
  

  const handleCategory = (event) => {
    setSelectedCategory(event.target.value);
  };

  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
  };

  const handleNameChange = (event) => {
    console.log(event.target.value);
    setName(event.target.value);
  };

  const handleArnameChange = (event) => {
    setArname(event.target.value)
  }

  const handleEnglishDescriptionChange = (content) => {
    setEnglishDescription(content);
  };

  const handleArabicDescriptionChange = (content) => {
    setArabicDescription(content);
  };

  const handlePriceChange = (event) => {
    setPrice(event.target.value);
  };

  const handleArabicPriceChange = (event) => {
    setArabicPrice(event.target.value);
  };

  const handleDiscountTypeChange = (event) => {
    setDiscountType(event.target.value);
  };

  const handleDiscountValueChange = (event) => {
    setDiscountValue(event.target.value);
  };

  const handleImageChange = (event) => {
    // Update the 'image' state with the selected file
    console.log(event.target.files[0]);
    setImage(event.target.files[0]);
  };

  const { paramsone } = useParams();

  const handleSubmit = async (e) => {
    console.log(image);
    e.preventDefault();
    const formData = new FormData();
    formData.append('category', category);
    formData.append('name', name);
    formData.append('arname', arname);
    formData.append('englishDescription', DOMPurify.sanitize(englishDescription));
    formData.append('arabicDescription', DOMPurify.sanitize(arabicDescription));
    formData.append('price', price);
    formData.append('arabicPrice', arabicPrice);
    formData.append('discountType', discountType);
    formData.append('discountValue', discountValue);
    formData.append('image', image);
    
    console.log(formData);
    try {
      const result = await createContent(formData, paramsone);
      if (result) {
        setContent(result.data)
        toast.success('Posted!')
      }else{
        toast.error('Not Posted')
      }
      console.log('Submit button clicked');
    } catch (error) {
      // Handle errors (e.g., show error message)
      console.error(error);
    }
  };

  useEffect(() => {
    const random = () => getContent().then(function(result) {
      setContent(result.data)
      console.log(result.data);
    })
    random();
  }, [])
  
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
    };

    window.addEventListener('resize', handleResize);

    // Clean up the event listener
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const deleteProd = async(e) => {
    try {
      const result = await deleteSingleProduct(e);
      if (result) {
        setContent(result.data)
        toast.success('Deleted!')
      }else{
        toast.error('Error in Delete')
      }
    } catch (error) {
      // Handle errors (e.g., show error message)
      console.error(error);
    }
  }

  const navigate = useNavigate();

  if (typeof content === 'undefined') {
    return (
      <h1>Loading...</h1>
    )
  }
  return (
    <>
      {!isMobile && 
      <>
      <Heading>
        <h3>Content</h3>
        <Typography>
          <span style={{ fontWeight: 600 }}>Home</span> &gt;{' '}
          <span style={{ fontWeight: 300 }}>Content</span>
        </Typography>
      </Heading>
      <MainBox>
      <MainContent component="form" onSubmit={handleSubmit}>
      <MainHeading>Add Content</MainHeading>
      <RichTextEditorWrapper>
        <EnglishHeading>Category</EnglishHeading>
        <Select style={{ width: '100%' }} value={category} onChange={handleCategoryChange}>
          <MenuItem value="Courses">Courses</MenuItem>
          <MenuItem value="Meditation">Meditation</MenuItem>
          <MenuItem value="E-Book">E-Book</MenuItem>
          <MenuItem value="Articles">Articles</MenuItem>
          <MenuItem value="Session">Session</MenuItem>
        </Select>
        <EnglishHeading>Name</EnglishHeading>
        <TextField style={{ width: '100%' }} value={name} onChange={handleNameChange} />
        <EnglishHeading>Slug</EnglishHeading>
        <TextField style={{ width: '100%', backgroundColor:"#e9e9ef" }} disabled />
        <ArabicWrapper>
          <ArabicHeading>اسم</ArabicHeading>
          <TextField style={{ width: '100%'  }} value={arname} onChange={handleArnameChange}/>
        </ArabicWrapper>
        <EnglishHeading>Description</EnglishHeading>
        <ReactQuill value={englishDescription} onChange={handleEnglishDescriptionChange} />
        <ArabicWrapper>
          <ArabicHeading>الصفحه الأولى</ArabicHeading>
          <ReactQuill value={arabicDescription} onChange={handleArabicDescriptionChange} />
        </ArabicWrapper>
        <EnglishHeading>Price</EnglishHeading>
        <TextField style={{ width: '100%' }} value={price} onChange={handlePriceChange} />
        <EnglishHeading>Arabic Price</EnglishHeading>
        <TextField style={{ width: '100%' }} value={arabicPrice} onChange={handleArabicPriceChange} />
        <EnglishHeading>Discount Type</EnglishHeading>
        <Select style={{ width: '100%' }} value={discountType} onChange={handleDiscountTypeChange}>
          <MenuItem value="Select Discount Type If Any">Select Discount Type If Any</MenuItem>
          <MenuItem value="Amount">Amount</MenuItem>
          <MenuItem value="Percent">percent</MenuItem>
          <MenuItem value="No Discount">No Discount</MenuItem>
        </Select>
        <EnglishHeading>Discount Value</EnglishHeading>
        <TextField style={{ width: '100%' }} value={discountValue} onChange={handleDiscountValueChange} />
        <EnglishHeading>Image</EnglishHeading>
        <Input type="file" accept="image/*" onChange={handleImageChange} /><br/>
        <span>Note :- The image should be in png, jpg, jpeg, gif only</span>
        <Btn>
          <Button style={{ color: 'white', width: '2vw', backgroundColor: '#2ab57d' }} type="submit">
            Submit
          </Button>
        </Btn>
      </RichTextEditorWrapper>
    </MainContent>
        <ViewContent>
          <EditHeading>
            Edit Content
            <Select style={{width: '30%', height:'inherit'}} value={selectedCategory} onChange={handleCategory}>
              <MenuItem value="Courses">Courses</MenuItem>
              <MenuItem value="Meditation">Meditation</MenuItem>
              <MenuItem value="E-Book">E-Book</MenuItem>
              <MenuItem value="Articles">Articles</MenuItem>
              <MenuItem value="Session">Session</MenuItem>
            </Select>
          </EditHeading>
          <EditContentHeading >
            <Written><h4>Id</h4></Written>
            <Written><h4>Name</h4></Written>
            <Written><h4>اسم</h4></Written>
            <Written><h4>Videos</h4></Written>
            <Written><h4>Links</h4></Written>
            <Written><h4>Action</h4></Written>
        </EditContentHeading>
        <UL>
          {
            content.map((res, key) => {
               if (res.category === selectedCategory) {
                categoryMatchFound = true;
                return (
                  <EditContent key={key}>
                    <Written>{key + 1}</Written>
                    <Written>{res.name}</Written>
                    <Written>{res.arname}</Written>
                    <Written>
                      <Delete onClick={() => {navigate(`/video/${res._id}`)}}>
                        <VideocamIcon />
                      </Delete>
                    </Written>
                    <Written>
                      <Delete onClick={() => {navigate(`/link/${res._id}`)}}>
                        <LinkIcon />
                      </Delete>
                    </Written>
                    <Written>
                      <Delete onClick={() => {navigate(`/editcontent/${res._id}`)}}>
                        <CreateIcon/>
                      </Delete>
                      <Delete onClick={() => deleteProd(res)}>
                        <DeleteIcon />
                      </Delete>
                    </Written>
                  </EditContent>
                );
              } else {
                // Render an empty fragment if the content's category doesn't match the selected category
                return null;
              }
            })
          }
          {!categoryMatchFound &&
              content.map((res, key) => (
                <EditContent key={key}>
                  <Written>{key + 1}</Written>
                  <Written>{res.name}</Written>
                  <Written>{res.arname}</Written>
                  <Written>
                    <Delete onClick={() => {navigate(`/video/${res._id}`)}}>
                      <VideocamIcon />
                    </Delete>
                  </Written>
                  <Written>
                    <Delete onClick={() => {navigate(`/link/${res._id}`)}}>
                      <LinkIcon />
                    </Delete>
                  </Written>
                  <Written>
                    <Delete onClick={() => {navigate(`/editcontent/${res._id}`)}}>
                      <CreateIcon />
                    </Delete>
                    <Delete onClick={() => deleteProd(res)}>
                      <DeleteIcon />
                    </Delete>
                  </Written>
                </EditContent>
              ))}
        </UL>
        </ViewContent>
      </MainBox>
      </>
      }
      {isMobile && 
      <>
      <Heading>
        <h3>Content</h3>
        <Typography>
          <span style={{ fontWeight: 600 }}>Home</span> &gt;{' '}
          <span style={{ fontWeight: 300 }}>Content</span>
        </Typography>
      </Heading>
      <MobileBox>
      <MainContent component='form' onSubmit={handleSubmit}>
        <MainHeading>
          Add Content
        </MainHeading>
        <RichTextEditorWrapper><EnglishHeading>Category</EnglishHeading>
        <Select style={{ width: '100%' }} value={category} onChange={handleCategoryChange}>
          <MenuItem value="Courses">Courses</MenuItem>
          <MenuItem value="Meditation">Meditation</MenuItem>
          <MenuItem value="E-Book">E-Book</MenuItem>
          <MenuItem value="Articles">Articles</MenuItem>
          <MenuItem value="Session">Session</MenuItem>
        </Select>
        <EnglishHeading>Name</EnglishHeading>
        <TextField style={{ width: '100%' }} value={name} onChange={handleNameChange} />
        <EnglishHeading>Slug</EnglishHeading>
        <TextField style={{ width: '100%' }} disabled />
        <ArabicWrapper>
          <ArabicHeading>اسم</ArabicHeading>
          <TextField style={{ width: '100%' }} value={arname} onChange={handleArnameChange}/>
        </ArabicWrapper>
        <EnglishHeading>Description</EnglishHeading>
        <ReactQuill value={englishDescription} onChange={handleEnglishDescriptionChange} />
        <ArabicWrapper>
          <ArabicHeading>الصفحه الأولى</ArabicHeading>
          <ReactQuill value={arabicDescription} onChange={handleArabicDescriptionChange} />
        </ArabicWrapper>
        <EnglishHeading>Price</EnglishHeading>
        <TextField style={{ width: '100%' }} value={price} onChange={handlePriceChange} />
        <EnglishHeading>Arabic Price</EnglishHeading>
        <TextField style={{ width: '100%' }} value={arabicPrice} onChange={handleArabicPriceChange} />
        <EnglishHeading>Discount Type</EnglishHeading>
        <Select style={{ width: '100%' }} value={discountType} onChange={handleDiscountTypeChange}>
          <MenuItem value="Select Discount Type If Any">Select Discount Type If Any</MenuItem>
          <MenuItem value="Amount">Amount</MenuItem>
          <MenuItem value="Percent">percent</MenuItem>
          <MenuItem value="No Discount">No Discount</MenuItem>
        </Select>
        {/* <TextField style={{ width: '100%' }} value={discountType} onChange={handleDiscountTypeChange} /> */}
        <EnglishHeading>Discount Value</EnglishHeading>
        <TextField style={{ width: '100%' }} value={discountValue} onChange={handleDiscountValueChange} />
        <EnglishHeading>Image</EnglishHeading>
        <Input type="file" accept="image/*" onChange={handleImageChange} />
        <Btn>
        <span>Note:-The image should be in png, jpg, jpeg, gif only</span>
          <Button style={{ color: 'white', width: '2vw', backgroundColor: '#2ab57d' }} type="submit">
            Submit
          </Button>
        </Btn>
        </RichTextEditorWrapper>
        </MainContent>
        <ViewContent>
          <EditHeading>
            Edit Content
            <Select style={{width: '30%', height:'inherit'}} value={selectedCategory} onChange={handleCategory}>
              <MenuItem value="Courses">Courses</MenuItem>
              <MenuItem value="Meditation">Meditation</MenuItem>
              <MenuItem value="E-Book">E-Book</MenuItem>
              <MenuItem value="Articles">Articles</MenuItem>
              <MenuItem value="Session">Session</MenuItem>
            </Select>
          </EditHeading>
          <MainHeadingWrapper>
            <EditContentHeading>
              <Item><h4>Id</h4></Item>
              <Item><h4>Name</h4></Item>
              <Item><h4>اسم</h4></Item>
              <Item><h4>Videos</h4></Item>
              <Item><h4>Links</h4></Item>
              <Item><h4>Action</h4></Item>
          </EditContentHeading>
        </MainHeadingWrapper>
        <UL>
        <ScrollableContainer>
          {
            content.map((res, key) => {
               if (res.category === selectedCategory) {
                categoryMatchFound = true;
                return (
                  
                  <EditContent key={key}>
                    <Item>{key + 1}</Item>
                    <Item>{res.name}</Item>
                    <Item>{res.arname}</Item>
                    <Item>
                      <Delete onClick={() => {navigate(`/video/${res._id}`)}}>
                        <VideocamIcon />
                      </Delete>
                    </Item>
                    <Item>
                      <Delete onClick={() => {navigate(`/link/${res._id}`)}}>
                        <LinkIcon />
                      </Delete>
                    </Item>
                    <Item>
                      <Delete onClick={() => {navigate(`/editcontent/${res._id}`)}}>
                        <CreateIcon />
                      </Delete>
                      <Delete onClick={() => deleteProd(res)}>
                        <DeleteIcon />
                      </Delete>
                    </Item>
                  </EditContent>
                  
                );
              } else {
                // Render an empty fragment if the content's category doesn't match the selected category
                return null;
              }
            })
          }
          {!categoryMatchFound &&
              content.map((res, key) => (
                
                <EditContent key={key}>
                  <Item>{key + 1}</Item>
                  <Item>{res.name}</Item>
                  <Item>{res.arname}</Item>
                  <Item>
                    <Delete onClick={() => {navigate(`/video/${res._id}`)}}>
                      <VideocamIcon />
                    </Delete>
                  </Item>
                  <Item>
                    <Delete onClick={() => {navigate(`/link/${res._id}`)}}>
                      <LinkIcon />
                    </Delete>
                  </Item>
                  <Item>
                    <Delete onClick={() => {navigate(`/editcontent/${res._id}`)}}>
                      <CreateIcon />
                    </Delete>
                    <Delete onClick={() => deleteProd(res)}>
                      <DeleteIcon />
                    </Delete>
                  </Item>
                </EditContent>
                
              ))}
            </ScrollableContainer>
        </UL>
        </ViewContent>
      </MobileBox>
      </>
      }
    </>
  )
}

export default Content
