import React, { useEffect, useState } from 'react'
import LibraryMusicIcon from '@mui/icons-material/LibraryMusic';
import styled from '@emotion/styled';
import { Box, Button, Input, MenuItem, Select, TextField, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import CreateIcon from '@mui/icons-material/Create';
import { createPodcast, deletePod, fetchPodcasts } from '../Service/api';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import DOMPurify from 'dompurify';

const Heading = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 20px;
`;


const MobHeading = styled(Box)`
  display: flex;
  height: 20px;
  justify-content: center;
  flex-direction: column;
  align-items: start;
`;

const MainHeading = styled(Box)`
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding: 1%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: start;
  height: 7vh;
`

const EditHeading = styled(Box)`
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding: 1%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: start;
  height: 7vh;
  @media (max-width: 767px) {
    height: 10vh;
  }
`



const MainContent = styled(Box)`
  border: 1px solid rgba(0, 0, 0, 0.2);
  margin-top: 1vh;
  width: 59%;
  @media (max-width: 767px) {
    margin-top: 1vh;
    padding: 1vh;
    width: 100%;
  }
`;

const ViewContent = styled(Box)`
  border: 1px solid rgba(0, 0, 0, 0.2);
  margin-top: 1vh;
  width: 40%;

  @media (max-width: 767px) {
    margin-top: 1vh;
    padding: 10px; /* Adjust padding for better mobile layout */
    width: 100%;
  }
`;


const MainBox = styled(Box)`
  width: auto;
  display: flex;
  justify-content: space-between;
`

const MobileBox = styled(Box)`
  display: flex;
  flex-direction: column;
`


const ArabicWrapper = styled.div`
  direction: rtl;
`;

const ArabicHeading = styled.h4`
  font-family: 'Arabic Font', Arial, sans-serif;
  text-align: right;
`;

const EnglishHeading = styled.h4`
  display: flex;
  justify-content: start;
`;

const RichTextEditorWrapper = styled(Box)`
  padding: 2%;
  .ql-container {
    border: 1px solid #ccc;
    min-height: 200px;
    text-align: right; /* Align the Arabic text inside the editor to the right */
  }
`;

const Btn = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: start;
  margin-top: 1vh;
  margin-bottom: 1vh;

  @media (max-width: 767px) {
    margin-top: 2vh;
    margin-bottom: 2vh;
  }
`;

const UL = styled.ul`
  margin: 0;
  padding: 0;
  margin-left: 1%;
`;

const EditContent = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 1%;
  margin-left: 1%;
  padding-left: 0;
  margin-left: 0;
  height: 5vh;
  background-color: #e9e9ef40;
  border-top: 1px solid rgba(0, 0, 0, 0.2);

  &:nth-of-type(2) {
    background-color: white;
  }

  @media (max-width: 767px) {
    height: auto;
    padding: 5px;
    margin-bottom: 5px;
  }
`;


const EditContentHeading = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 2%;
  padding-right: 2%;
  min-height: 2vh;
`;

const Written = styled(Box)`
  min-height: 1.5vh;
  width: 10vw;
  display: flex;
  word-wrap: break-word;
  overflow: hidden;
  justify-content: start;
`;

const Delete = styled(Box)`
  height: 28px;
  width: 2vw;
  border: 1px solid rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #74788D;
  cursor: pointer;

  @media (max-width: 767px) {
    height: 30px;
    width: 30px;
    font-size: 18px;
  }
`;

const ArabicTextField = styled(TextField)`
  direction: rtl;
`;






const Content = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const [category, setCategory] = useState('');
  const [name, setName] = useState('');
  const [arname, setArname] = useState('');
  const [englishDescription, setEnglishDescription] = useState('');
  const [arabicDescription, setArabicDescription] = useState('');
  const [image, setImage] = useState('');
  const [content, setContent] = useState([]);


  const handleNameChange = (event) => {
    console.log(event.target.value);
    setName(event.target.value);
  };

  const handleArnameChange = (event) => {
    setArname(event.target.value)
  }

  const handleEnglishDescriptionChange = (content) => {
    setEnglishDescription(content);
  };

  const handleArabicDescriptionChange = (content) => {
    setArabicDescription(content);
  };

  useEffect(() => {
    const random = () => fetchPodcasts().then(function(result) {
      setContent(result.data)
      console.log(result.data);
    })
    random();
  }, [])

  const handleSubmit = async (e) => {
    console.log(image);
    e.preventDefault();
    const formData = new FormData();
    formData.append('name', name);
    formData.append('arname', arname);
    formData.append('englishDescription', DOMPurify.sanitize(englishDescription));
    formData.append('arabicDescription', DOMPurify.sanitize(arabicDescription));
    formData.append('image', image);
    
    console.log(formData);
    try {
      const result = await createPodcast(formData);
      if (result) {
        setContent(result.data)
        toast.success('Posted!')
        console.log(result);
      }else{
        toast.error('Not Posted')
      }
    } catch (error) {
      // Handle errors (e.g., show error message)
      console.error(error);
    }
  };

  const navigate = useNavigate();

  const handleImageChange = (event) => {
    // Update the 'image' state with the selected file
    console.log(event.target.files[0]);
    setImage(event.target.files[0]);
  };

  const deleteProd = async(e) => {
    try {
      const result = await deletePod(e);
      if (result) {
        setContent(result.data)
        toast.success('Deleted!')
      }else{
        toast.error('Error in Delete')
      }
    } catch (error) {
      // Handle errors (e.g., show error message)
      console.error(error);
    }
  }

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
    };

    window.addEventListener('resize', handleResize);

    // Clean up the event listener
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <>
      {!isMobile && 
      <>
      <Heading>
        <h3>Podcast</h3>
        <Typography>
          <span style={{ fontWeight: 600 }}>Home</span> &gt;{' '}
          <span style={{ fontWeight: 300 }}>Podcast</span>
        </Typography>
      </Heading>
      <MainBox>
      <MainContent component="form" onSubmit={handleSubmit}>
        <MainHeading>
          Add Podcast
        </MainHeading>
        <RichTextEditorWrapper>
          
        <EnglishHeading>Name</EnglishHeading>
        <TextField style={{ width: '100%' }} value={name} onChange={handleNameChange} />
        <EnglishHeading>Slug</EnglishHeading>
        <TextField style={{ width: '100%' }} disabled />
        <ArabicWrapper>
          <ArabicHeading>اسم</ArabicHeading>
          <TextField style={{ width: '100%' }} value={arname} onChange={handleArnameChange}/>
        </ArabicWrapper>
        <EnglishHeading>Description</EnglishHeading>
        <ReactQuill value={englishDescription} onChange={handleEnglishDescriptionChange} />
        <ArabicWrapper>
          <ArabicHeading>الصفحه الأولى</ArabicHeading>
          <ReactQuill value={arabicDescription} onChange={handleArabicDescriptionChange} />
        </ArabicWrapper>
          
        <EnglishHeading>Image</EnglishHeading>
        <Input type="file" accept="image/*" onChange={handleImageChange} />
          <Typography style={{textAlign: 'start'}}>Note:- The image should be in png, jpg, jpeg, gif only</Typography>
          <Btn>
          <Button style={{ color: 'white', width: '2vw', backgroundColor: '#2ab57d' }} type='submit'>
            Submit
          </Button>
        </Btn>
        </RichTextEditorWrapper>
        </MainContent>
        <ViewContent>
          <EditHeading>
            Edit Content
          </EditHeading>
          <EditContentHeading>
            <Written><h4>Id</h4></Written>
            <Written><h4>Name</h4></Written>
            <Written><h4>اسم</h4></Written>
            <Written><h4>Audio</h4></Written>
            <Written><h4>Action</h4></Written>
        </EditContentHeading>
        <UL>
          {content && content.map((cont, key) => (
            <EditContent key={key}>
              <Written>{key+1}</Written>
              <Written>{cont.name}</Written>
              <Written>{cont.arname}</Written>
              <Written><Delete onClick={() => {navigate(`/audio/${cont._id}`)}}><LibraryMusicIcon /></Delete></Written>
              <Written><Delete onClick={() => {navigate(`/podcast/${cont._id}`)}}><CreateIcon /></Delete><Delete onClick={() => deleteProd(cont)}><DeleteIcon /></Delete></Written>
            </EditContent>
          ))}
        </UL>
        </ViewContent>
      </MainBox>
      </>
      }
      {isMobile && 
      <>
      <Heading>
        <h3>Podcast</h3>
        <Typography>
          <span style={{ fontWeight: 600 }}>Home</span> &gt;{' '}
          <span style={{ fontWeight: 300 }}>Podcast</span>
        </Typography>
      </Heading>
      <MobileBox>
      <MainContent component="form" onSubmit={handleSubmit}>
        <MainHeading>
          Add Podcast
        </MainHeading>
        <RichTextEditorWrapper>
        <EnglishHeading>Name</EnglishHeading>
        <TextField style={{ width: '100%' }} value={name} onChange={handleNameChange} />
        <EnglishHeading>Slug</EnglishHeading>
        <TextField style={{ width: '100%' }} disabled />
        <ArabicWrapper>
          <ArabicHeading>اسم</ArabicHeading>
          <TextField style={{ width: '100%' }} value={arname} onChange={handleArnameChange}/>
        </ArabicWrapper>
        <EnglishHeading>Description</EnglishHeading>
        <ReactQuill value={englishDescription} onChange={handleEnglishDescriptionChange} />
        <ArabicWrapper>
          <ArabicHeading>الصفحه الأولى</ArabicHeading>
          <ReactQuill value={arabicDescription} onChange={handleArabicDescriptionChange} />
        </ArabicWrapper>
          
        <EnglishHeading>Image</EnglishHeading>
        <Input type="file" accept="image/*" onChange={handleImageChange} />
          <Typography style={{textAlign: 'start'}}>Note:- The image should be in png, jpg, jpeg, gif only</Typography>
          <Btn>
          <Button style={{ color: 'white', width: '2vw', backgroundColor: '#2ab57d' }} type='submit'>
            Submit
          </Button>
        </Btn>
        </RichTextEditorWrapper>
        </MainContent>
        <ViewContent>
          <EditHeading>
            Edit Content
          </EditHeading>
          <EditContentHeading>
            <Written><h4>Id</h4></Written>
            <Written><h4>Name</h4></Written>
            <Written><h4>اسم</h4></Written>
            <Written><h4>Audio</h4></Written>
            <Written><h4>Action</h4></Written>
        </EditContentHeading>
        <UL>
        {content && content.map((cont, key) => (
            <EditContent key={key}>
              <Written>{key+1}</Written>
              <Written>{cont.name}</Written>
              <Written>{cont.arname}</Written>
              <Written><Delete onClick={() => {navigate(`/audio/${cont._id}`)}}><LibraryMusicIcon /></Delete></Written>
              <Written><Delete onClick={() => {navigate(`/podcast/${cont._id}`)}}><CreateIcon /></Delete><Delete onClick={() => deleteProd(cont)}><DeleteIcon /></Delete></Written>
            </EditContent>
          ))}
        </UL>
        </ViewContent>
      </MobileBox>
      </>
      }
    </>
  )
}

export default Content
