import React, { useEffect, useState } from 'react'
import Layout from './Layout'
import styled from '@emotion/styled';
import { Box, Button, Checkbox, FormControlLabel, Input, MenuItem, Select, TextField, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import VideocamIcon from '@mui/icons-material/Videocam';
import CreateIcon from '@mui/icons-material/Create';
import LinkIcon from '@mui/icons-material/Link';
import { CheckBox } from '@mui/icons-material';
import { getUsers, postEmail } from '../Service/api';

const Heading = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 20px;
`;


const MobHeading = styled(Box)`
  display: flex;
  height: 20px;
  justify-content: center;
  flex-direction: column;
  align-items: start;
`;

const MainHeading = styled(Box)`
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding: 1%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: start;
  height: 7vh;
`

const EditHeading = styled(Box)`
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding: 1%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: start;
  height: 7vh;
  @media (max-width: 767px) {
    height: 10vh;
  }
`



const MainContent = styled(Box)`
  border: 1px solid rgba(0, 0, 0, 0.2);
  margin-top: 1vh;
  width: 48%;
  @media (max-width: 767px) {
    margin-top: 1vh;
    padding: 1vh;
    width: 100%;
  }
`;

const ViewContent = styled(Box)`
  border: 1px solid rgba(0, 0, 0, 0.2);
  margin-top: 1vh;
  width: 51%;

  @media (max-width: 767px) {
    margin-top: 1vh;
    padding: 10px; /* Adjust padding for better mobile layout */
    width: 100%;
  }
`;


const MainBox = styled(Box)`
  width: auto;
  display: flex;
  justify-content: space-between;
`

const MobileBox = styled(Box)`
  display: flex;
  flex-direction: column;
`


const ArabicWrapper = styled.div`
  direction: rtl;
`;

const ArabicHeading = styled.h4`
  font-family: 'Arabic Font', Arial, sans-serif;
  text-align: right;
`;

const EnglishHeading = styled.h4`
  display: flex;
  justify-content: start;
`;

const RichTextEditorWrapper = styled(Box)`
  padding: 2%;
  .ql-container {
    border: 1px solid #ccc;
    min-height: 200px;
    text-align: right; /* Align the Arabic text inside the editor to the right */
  }
`;

const Btn = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: start;
  margin-top: 1vh;
  margin-bottom: 1vh;

  @media (max-width: 767px) {
    margin-top: 2vh;
    margin-bottom: 2vh;
  }
`;

const UL = styled.ul`
  margin: 0;
  padding: 0;
  margin-left: 1%;
`;

const EditContent = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 1%;
  margin-left: 1%;
  padding-left: 0;
  margin-left: 0;
  height: 5vh;
  background-color: #e9e9ef40;
  border-top: 1px solid rgba(0, 0, 0, 0.2);

  &:nth-of-type(2) {
    background-color: white;
  }

  @media (max-width: 767px) {
    height: auto;
    padding: 5px;
    margin-bottom: 5px;
  }
`;


const EditContentHeading = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 2%;
  padding-right: 2%;
  min-height: 2vh;
`;

const Written = styled(Box)`
  min-height: 1.5vh;
  width: 10vw;
  display: flex;
  word-wrap: break-word;
  overflow: hidden;
  justify-content: start;
`;

const Delete = styled(Box)`
  height: 28px;
  width: 2vw;
  border: 1px solid rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #74788D;
  cursor: pointer;

  @media (max-width: 767px) {
    height: 30px;
    width: 30px;
    font-size: 18px;
  }
`;

const ArabicTextField = styled(TextField)`
  direction: rtl;
`;

const MainHeadingWrapper = styled.div`
  /* Enable horizontal scroll for mobile screens */
  overflow-x: auto;

  /* Hide horizontal scrollbar */
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`;


const Item = styled(Box)`
  padding: 0 10px;
  white-space: nowrap; /* Prevent item text from wrapping */
`;

const ScrollableContainer = styled.div`
  /* Enable horizontal scroll for mobile screens */
  overflow-x: auto;

  /* Hide horizontal scrollbar */
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`;



const SendEmail = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const [data, setData] = useState()
  const [englishName, setEnglishName] = useState('');
  const [arabicName, setArabicName] = useState('');
  const [englishPrice, setEnglishPrice] = useState('');
  const [arabicPrice, setArabicPrice] = useState('');
  const [englishDescription, setEnglishDescription] = useState('');
  const [arabicDescription, setArabicDescription] = useState('');
  const [signup, setSignup] = useState('');

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
    };

    window.addEventListener('resize', handleResize);

    // Clean up the event listener
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const random = () => getUsers().then(function(result) {
      setData(result.data)
    })
    random()
  }, [])

  const onInputChange = (e) => {
    setSignup({...signup, [e.target.name]: e.target.value})
  }

  const sendEmail = async (dat) => {
    const res = await postEmail(dat)
  }
  if(typeof data === 'undefined') {
    return (
      <h1>Loading...</h1>
    )
  }
  return (
    <>
      {!isMobile && 
      <>
      <Heading>
        <h3>Send Email</h3>
        <Typography>
          <span style={{ fontWeight: 600 }}>Home</span> &gt;{' '}
          <span style={{ fontWeight: 300 }}>Send Email</span>
        </Typography>
      </Heading>
      <MainBox>
      <MainContent>
        <MainHeading>
          Mail Detail
        </MainHeading>
        <RichTextEditorWrapper>
          <ArabicWrapper>
            <ArabicHeading>موضوع</ArabicHeading>
            <TextField style={{width: '100%'}}
            name='name'
            onChange={(e) => onInputChange(e)}
            />
          </ArabicWrapper>

          <ArabicWrapper>
            <ArabicHeading>محتوى البريد</ArabicHeading>
            <TextField
              multiline={true}
              rows={7}
              style={{width: '100%'}}
              name='body'
              onChange={(e) => onInputChange(e)}
            />
          </ArabicWrapper>
      
        </RichTextEditorWrapper>
        </MainContent>
        <ViewContent>
          <EditContentHeading>
            <Written><h4>Customer Name</h4></Written>
            <Written><h4>Email</h4></Written>
            <Written><h4><FormControlLabel control={<Checkbox />} label="Select All" /></h4></Written>
            <Written><h4>Action</h4></Written>
          </EditContentHeading>
        <UL>
          {data && data.map((dat, key) => (
            <EditContent key={key}>
              <Written>{dat.name}</Written>
              <Written>{dat.email}</Written>
              <Written><FormControlLabel control={<Checkbox />} /></Written>
              <Written><Button style={{backgroundColor: '#4ba6ef', color: '#fff', lineHeight:"17px"}} onClick={() => sendEmail(dat)}>Send Single</Button></Written>
            </EditContent>
          ))}
        </UL>
        </ViewContent>
      </MainBox>
      </>
      }
      {isMobile && 
      <>
      <Heading>
        <h3>Send Email</h3>
        <Typography>
          <span style={{ fontWeight: 600 }}>Home</span> &gt;{' '}
          <span style={{ fontWeight: 300 }}>Send Email</span>
        </Typography>
      </Heading>
      <MobileBox>
      <MainContent>
        <MainHeading>
          Mail Detail
        </MainHeading>
        <RichTextEditorWrapper>
          <ArabicWrapper>
            <ArabicHeading>موضوع</ArabicHeading>
            <TextField style={{width: '100%'}}
             name='name'
             onChange={(e) => onInputChange(e)}/>
          </ArabicWrapper>

          <ArabicWrapper>
            <ArabicHeading>محتوى البريد</ArabicHeading>
            <TextField
              multiline={true}
              rows={7}
              style={{width: '100%'}}
              name='body'
              onChange={(e) => onInputChange(e)}
            />
          </ArabicWrapper>
      
        </RichTextEditorWrapper>
        </MainContent>
        <ViewContent>
          <EditContentHeading>
            <Written><h4>Customer Name</h4></Written>
            <Written><h4>Email</h4></Written>
            <Written><h4><FormControlLabel control={<Checkbox />} label="Select All" /></h4></Written>
            <Written><h4>Action</h4></Written>
          </EditContentHeading>
        <UL>
          {data && data.map((dat, key) => (
            <EditContent key={key}>
              <Written>{dat.name}</Written>
              <Written>{dat.email}</Written>
              <Written><FormControlLabel control={<Checkbox />} /></Written>
              <Written><Button style={{backgroundColor: '#4ba6ef', color: '#fff', lineHeight:"17px"}} onClick={() => sendEmail(dat)}>Send Single</Button></Written>
            </EditContent>
          ))}
        </UL>
        </ViewContent>
      </MobileBox>
      </>
      }
    </>
  )
}

export default SendEmail
