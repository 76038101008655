// AuthContext.js
import React, { createContext, useReducer } from 'react';
import { authReducer, initialState } from '../reducers/AuthReducer';
import axios from 'axios';
import { toast } from 'react-toastify';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);

  const URL = 'http://localhost:3000'; // Replace this with your API URL

  const login = async (data) => {
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };

      // Make the API request with the payload
      const response = await axios.post(`${URL}/api/user/signin`, data, config);
      // Assuming the API returns some user data upon successful login
      if (response.data.isAdmin === 'true') {
        const userData = response.data;
        localStorage.setItem('userId', userData.id);
        localStorage.setItem('token', JSON.stringify(userData.token));
        console.log(userData);
        // Update the state using dispatch
        dispatch({ type: 'LOGIN', payload: userData });
        toast.success('Login successful! Welcome back.');
      }else{
        toast.error('Sorry You are Not Authorized!')
      }
    } catch (error) {
        if (error.response && error.response.status === 401 ) {
          // Unauthorized, show error toast
          toast.error('Invalid credentials. Please try again.');
        } else {
          console.error('Login failed:', error);
        }
      }
  };

  return (
    <AuthContext.Provider value={{ login }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
