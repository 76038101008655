import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { Box, Button, Input, MenuItem, Select, TextField, Typography } from '@mui/material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill's CSS
import { createTestimonials, getSingleProduct, updateSingleProduct } from '../Service/api';
import { toast } from 'react-toastify';
import DOMPurify from 'dompurify';
import { useParams } from 'react-router-dom';

const Heading = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 20px;
`;

const ArabicWrapper = styled.div`
  direction: rtl;
`;

const ArabicHeading = styled.h4`
  font-family: 'Arabic Font', Arial, sans-serif;
  text-align: right;
`;

const EnglishHeading = styled.h4`
  display: flex;
  justify-content: start;
`;

const RichTextEditorWrapper = styled(Box)`
  margin-top: 20px;
  .ql-container {
    border: 1px solid #ccc;
    min-height: 200px;
    text-align: right; /* Align the Arabic text inside the editor to the right */
  }
`;

const Content = styled(Box)`
  border: 1px solid rgba(0, 0, 0, 0.2);
  margin: 1vw;
  padding: 1vw;

  @media (max-width: 767px) {
    margin: 1vh;
    padding: 1vh;
  }
`;

const Btn = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: start;
  margin-top: 1vh;
  margin-bottom: 1vh;

  @media (max-width: 767px) {
    margin-top: 2vh;
    margin-bottom: 2vh;
  }
`;



const MobHeading = styled(Box)`
  display: flex;
  height: 20px
  justify-content: center;
  flex-direction: column;
  align-items: start;
`


const EditContent = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const [category, setCategory] = useState('');
  const [name, setName] = useState('');
  const [arname, setArname] = useState('');
  const [englishDescription, setEnglishDescription] = useState('');
  const [arabicDescription, setArabicDescription] = useState('');
  const [price, setPrice] = useState('');
  const [arabicPrice, setArabicPrice] = useState('');
  const [discountType, setDiscountType] = useState('');
  const [discountValue, setDiscountValue] = useState('');
  const [image, setImage] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [content, setContent] = useState([]);
  let categoryMatchFound = false;
  const {paramsthree} = useParams()

  const handleCategory = (event) => {
    setSelectedCategory(event.target.value);
  };

  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
  };

  const handleNameChange = (event) => {
    console.log(event.target.value);
    setName(event.target.value);
  };

  const handleArnameChange = (event) => {
    setArname(event.target.value)
  }

  const handleEnglishDescriptionChange = (content) => {
    setEnglishDescription(content);
  };

  const handleArabicDescriptionChange = (content) => {
    setArabicDescription(content);
  };

  const handlePriceChange = (event) => {
    setPrice(event.target.value);
  };

  const handleArabicPriceChange = (event) => {
    setArabicPrice(event.target.value);
  };

  const handleDiscountTypeChange = (event) => {
    setDiscountType(event.target.value);
  };

  const handleDiscountValueChange = (event) => {
    setDiscountValue(event.target.value);
  };

  const handleImageChange = (event) => {
    // Update the 'image' state with the selected file
    console.log(event.target.files[0]);
    setImage(event.target.files[0]);
  };


  useEffect(() => {
    const fetchContent = async () => {
      try {
        const result = await getSingleProduct(paramsthree);
        const singleContent = result.data;
        setCategory(singleContent.category);
        setName(singleContent.name);
        setArname(singleContent.arname);
        setEnglishDescription(DOMPurify.sanitize(singleContent.des));
        setArabicDescription(DOMPurify.sanitize(singleContent.ardes));
        setPrice(singleContent.price);
        setArabicPrice(singleContent.arprice);
        setDiscountType(singleContent.discounttype);
        setDiscountValue(singleContent.discountvalue);
        setImage(singleContent.image)
        console.log(singleContent);
      } catch (error) {
        // Handle errors (e.g., show error message)
        console.error(error);
        // Optionally, show a toast message with an error notification
        toast.error('Error fetching content data.');
      }
    };

    fetchContent();
  }, [paramsthree]);

  const handleSubmit = async (e) => {
    console.log(image);
    e.preventDefault();
    const formData = new FormData();
    formData.append('category', category);
    formData.append('name', name);
    formData.append('arname', arname);
    formData.append('englishDescription', englishDescription);
    formData.append('arabicDescription', arabicDescription);
    formData.append('price', price);
    formData.append('arabicPrice', arabicPrice);
    formData.append('discountType', discountType);
    formData.append('discountValue', discountValue);
    formData.append('image', image);
    
    console.log(formData);
    try {
      const result = await updateSingleProduct(formData, paramsthree);
      if (result) {
        toast.success('Posted!')
      }else{
        toast.error('Not Posted')
      }
      console.log('Submit button clicked');
    } catch (error) {
      // Handle errors (e.g., show error message)
      console.error(error);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
    };

    window.addEventListener('resize', handleResize);

    // Clean up the event listener
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (typeof content === 'undefined') {
    return(
        <h1>Loading...</h1>
    )
  }

  

  return (
    <>
      {!isMobile && <Heading>
        <h3>Edit Content</h3>
        <Typography>
          <span style={{ fontWeight: 600 }}>Form</span> &gt;{' '}
          <span style={{ fontWeight: 300 }}>Edit Content</span>
        </Typography>
      </Heading>}
      {isMobile && <MobHeading>
        <h3>Edit Content</h3>
        <Typography>
          <span style={{ fontWeight: 600 }}>Podcast</span> &gt;{' '}
          <span style={{ fontWeight: 300 }}>Add Podcast</span>
        </Typography>
      </MobHeading>}
      <Content component='form' noValidate onSubmit={handleSubmit}>
        <RichTextEditorWrapper>
            <EnglishHeading>Category</EnglishHeading>
            <Select style={{ width: '100%' }} value={category} onChange={handleCategoryChange}>
            <MenuItem value="Courses">Courses</MenuItem>
            <MenuItem value="Meditation">Meditation</MenuItem>
            <MenuItem value="E-Book">E-Book</MenuItem>
            <MenuItem value="Articles">Articles</MenuItem>
            <MenuItem value="Session">Session</MenuItem>
            </Select>
            <EnglishHeading>Name</EnglishHeading>
            <TextField style={{ width: '100%' }} value={name} onChange={handleNameChange} />
            <EnglishHeading>Slug</EnglishHeading>
            <TextField style={{ width: '100%' }} disabled />
            <ArabicWrapper>
            <ArabicHeading>اسم</ArabicHeading>
            <TextField style={{ width: '100%' }} value={arname} onChange={handleArnameChange}/>
            </ArabicWrapper>
            <EnglishHeading>Description</EnglishHeading>
            <ReactQuill value={englishDescription} onChange={handleEnglishDescriptionChange} />
            <ArabicWrapper>
            <ArabicHeading>الصفحه الأولى</ArabicHeading>
            <ReactQuill value={arabicDescription} onChange={handleArabicDescriptionChange} />
            </ArabicWrapper>
            <EnglishHeading>Price</EnglishHeading>
            <TextField style={{ width: '100%' }} value={price} onChange={handlePriceChange} />
            <EnglishHeading>Arabic Price</EnglishHeading>
            <TextField style={{ width: '100%' }} value={arabicPrice} onChange={handleArabicPriceChange} />
            <EnglishHeading>Discount Type</EnglishHeading>
            <TextField style={{ width: '100%' }} value={discountType} onChange={handleDiscountTypeChange} />
            <EnglishHeading>Discount Value</EnglishHeading>
            <TextField style={{ width: '100%' }} value={discountValue} onChange={handleDiscountValueChange} />
            <EnglishHeading>Image</EnglishHeading>
            <Input type="file" accept="image/*" onChange={handleImageChange} />
            <Btn>
            <Button style={{ color: 'white', width: '2vw', backgroundColor: '#2ab57d' }} type="submit">
                Submit
            </Button>
            </Btn>
        </RichTextEditorWrapper>
      </Content>
    </>
  );
};

export default EditContent;
