import React, { useEffect, useState } from 'react'
import Layout from './Layout'
import styled from '@emotion/styled';
import { Box, Button, Checkbox, FormControlLabel, Input, MenuItem, Select, TextField, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import VideocamIcon from '@mui/icons-material/Videocam';
import CreateIcon from '@mui/icons-material/Create';
import LinkIcon from '@mui/icons-material/Link';
import { addUser, deleteU, fetchUser } from '../Service/api';
import { toast } from 'react-toastify';

const Heading = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 20px;
`;


const MobHeading = styled(Box)`
  display: flex;
  height: 20px;
  justify-content: center;
  flex-direction: column;
  align-items: start;
`;

const MainHeading = styled(Box)`
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding: 1%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: start;
  height: 7vh;
`

const EditHeading = styled(Box)`
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding: 1%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: start;
  height: 7vh;
  @media (max-width: 767px) {
    height: 10vh;
  }
`



const MainContent = styled(Box)`
  border: 1px solid rgba(0, 0, 0, 0.2);
  margin-top: 1vh;
  width: 59%;
  @media (max-width: 767px) {
    margin-top: 1vh;
    padding: 1vh;
    width: 100%;
  }
`;

const ViewContent = styled(Box)`
  border: 1px solid rgba(0, 0, 0, 0.2);
  margin-top: 1vh;
  width: 40%;

  @media (max-width: 767px) {
    margin-top: 1vh;
    padding: 10px; /* Adjust padding for better mobile layout */
    width: 100%;
  }
`;


const MainBox = styled(Box)`
  width: auto;
  display: flex;
  justify-content: space-between;
`

const MobileBox = styled(Box)`
  display: flex;
  flex-direction: column;
`


const ArabicWrapper = styled.div`
  direction: rtl;
`;

const ArabicHeading = styled.h4`
  font-family: 'Arabic Font', Arial, sans-serif;
  text-align: right;
`;

const EnglishHeading = styled.h4`
  display: flex;
  justify-content: start;
`;

const RichTextEditorWrapper = styled(Box)`
  padding: 2%;
  .ql-container {
    border: 1px solid #ccc;
    min-height: 200px;
    text-align: right; /* Align the Arabic text inside the editor to the right */
  }
`;

const Btn = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: start;
  margin-top: 1vh;
  margin-bottom: 1vh;

  @media (max-width: 767px) {
    margin-top: 2vh;
    margin-bottom: 2vh;
  }
`;

const UL = styled.ul`
  margin: 0;
  padding: 0;
  margin-left: 1%;
`;

const EditContent = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 1%;
  margin-left: 1%;
  padding-left: 0;
  margin-left: 0;
  height: 5vh;
  background-color: #e9e9ef40;
  border-top: 1px solid rgba(0, 0, 0, 0.2);

  &:nth-of-type(2) {
    background-color: white;
  }

  @media (max-width: 767px) {
    height: auto;
    padding: 5px;
    margin-bottom: 5px;
  }
`;


const EditContentHeading = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 2%;
  padding-right: 2%;
  min-height: 2vh;
`;

const Written = styled(Box)`
  min-height: 1.5vh;
  width: 5vw;
  word-wrap: break-word;
  overflow: hidden;
  display: flex;
  justify-content: start;
`;

const Delete = styled(Box)`
  height: 28px;
  width: 2vw;
  border: 1px solid rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #74788D;
  cursor: pointer;

  @media (max-width: 767px) {
    height: 30px;
    width: 30px;
    font-size: 18px;
  }
`;

const ArabicTextField = styled(TextField)`
  direction: rtl;
`;




const AddUser = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const [contents, setContents] = useState();
  const [formData, setFormData] = useState({
    name: '',
    mail: '',
    phone: '',
    password: '',
    isAdmin: false,
  });

  const handleCheckboxToggle = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      isAdmin: !prevFormData.isAdmin,
    }));
  };

  // Function to handle form input change
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      // Call the addUser function to make the API request
      const response = await addUser(formData);

      // Handle the response as needed
      console.log(response);
      setContents(response.data) // You can do something with the response here if needed
    } catch (error) {
      // Handle error if the API call fails
      console.error(error);
    }
  };


  const deleteUser = async(e) => {
    try {
      const result = await deleteU(e);
      if (result) {
        setContents(result.data)
        toast.success('Deleted!')
      }else{
        toast.error('Error in Delete')
      }
    } catch (error) {
      // Handle errors (e.g., show error message)
      console.error(error);
    }
  }

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
    };

    window.addEventListener('resize', handleResize);

    // Clean up the event listener
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const random = () => fetchUser().then(function(result) {
      setContents(result.data)
      console.log(result);
    })
    random();
  }, [])

  if (typeof contents === 'undefined') {
    return(
      <h1>Loading...</h1>
    )
  }
  return (
    <>
      {!isMobile && 
      <>
      <Heading>
        <h3>User</h3>
        <Typography>
          <span style={{ fontWeight: 600 }}>Forms</span> &gt;{' '}
          <span style={{ fontWeight: 300 }}>Add User</span>
        </Typography>
      </Heading>
      <MainBox>
      <MainContent>
        <MainHeading>
          User
        </MainHeading>
        <RichTextEditorWrapper>
      <form onSubmit={handleSubmit}>
        <EnglishHeading>Name</EnglishHeading>
        <TextField
          name="name"
          value={formData.name}
          onChange={handleInputChange}
          style={{ width: '100%' }}
        />

        <EnglishHeading>Mail</EnglishHeading>
        <TextField
          name="mail"
          value={formData.mail}
          onChange={handleInputChange}
          style={{ width: '100%' }}
        />

        <EnglishHeading>Phone</EnglishHeading>
        <TextField
          name="phone"
          value={formData.phone}
          onChange={handleInputChange}
          style={{ width: '100%' }}
        />

        <EnglishHeading>Password</EnglishHeading>
        <TextField
          name="password"
          value={formData.password}
          type='password'
          onChange={handleInputChange}
          style={{ width: '100%' }}
        />

        <EnglishHeading>
          <FormControlLabel
            control={
              <Checkbox
                checked={formData.isAdmin}
                onChange={handleCheckboxToggle}
                name="isAdmin"
              />
            }
            label="is Admin"
          />
        </EnglishHeading>

        <Btn>
          <Button
            style={{ color: 'white', width: '5vw', backgroundColor: '#2ab57d' }}
            type="submit"
          >
            Add User
          </Button>
        </Btn>
      </form>
    </RichTextEditorWrapper>
        </MainContent>
        <ViewContent>
          <EditHeading>
            Edit Content
          </EditHeading>
          <EditContentHeading>
            <Written><h4>Id</h4></Written>
            <Written><h4>Name</h4></Written>
            <Written><h4>Email</h4></Written>
            <Written><h4>Phone</h4></Written>
            <Written><h4>Action</h4></Written>
        </EditContentHeading>
        <UL>
        {contents && contents.map((cont, key) => {
            return (<EditContent key={key}>
              <Written>{key+1}</Written>
              <Written>{cont.name}</Written>
              <Written>{cont.email}</Written>
              <Written>{cont.phone}</Written>
              <Written><Delete><CreateIcon /></Delete><Delete onClick={() => deleteUser(cont)}><DeleteIcon /></Delete></Written>
            </EditContent>
          )
          }
          )}
        </UL>
        </ViewContent>
      </MainBox>
      </>
      }
      {isMobile && 
      <>
      <Heading>
        <h3>Content</h3>
        <Typography>
          <span style={{ fontWeight: 600 }}>Home</span> &gt;{' '}
          <span style={{ fontWeight: 300 }}>Content</span>
        </Typography>
      </Heading>
      <MobileBox>
      <MainContent>
        <MainHeading>
          Add Content
        </MainHeading>
        <RichTextEditorWrapper>
      <form onSubmit={handleSubmit}>
        <EnglishHeading>Name</EnglishHeading>
        <TextField
          name="name"
          value={formData.name}
          onChange={handleInputChange}
          style={{ width: '100%' }}
        />

        <EnglishHeading>Mail</EnglishHeading>
        <TextField
          name="mail"
          value={formData.mail}
          onChange={handleInputChange}
          style={{ width: '100%' }}
        />

        <EnglishHeading>Phone</EnglishHeading>
        <TextField
          name="phone"
          value={formData.phone}
          onChange={handleInputChange}
          style={{ width: '100%' }}
        />

        <EnglishHeading>Password</EnglishHeading>
        <TextField
          name="password"
          value={formData.password}
          type='password'
          onChange={handleInputChange}
          style={{ width: '100%' }}
        />

        <EnglishHeading>
          <FormControlLabel
            control={
              <Checkbox
                checked={formData.isAdmin}
                onChange={handleCheckboxToggle}
                name="isAdmin"
              />
            }
            label="Is Admin"
          />
        </EnglishHeading>

        <Btn>
          <Button
            style={{ color: 'white', width: '5vw', backgroundColor: '#2ab57d' }}
            type="submit"
          >
            Add User
          </Button>
        </Btn>
      </form>
    </RichTextEditorWrapper>
        </MainContent>
        <ViewContent>
          <EditHeading>
            Edit Content
            
          </EditHeading>
          <EditContentHeading>
            <Written><h4>Id</h4></Written>
            <Written><h4>Name</h4></Written>
            <Written><h4>Email</h4></Written>
            <Written><h4>Phone</h4></Written>
            <Written><h4>Action</h4></Written>
        </EditContentHeading>
        <UL>
        {contents && contents.map((cont, key) => {
            return (<EditContent key={key}>
              <Written>{key+1}</Written>
              <Written>{cont.name}</Written>
              <Written>{cont.email}</Written>
              <Written>{cont.phone}</Written>
              <Written><Delete><CreateIcon /></Delete><Delete onClick={() => deleteUser(cont)}><DeleteIcon /></Delete></Written>
            </EditContent>
          )
          }
          )}
        </UL>
        </ViewContent>
      </MobileBox>
      </>
      }
    </>
  )
}

export default AddUser
