import React, { useEffect, useState } from 'react';
import Layout from './Layout';
import styled from '@emotion/styled';
import { Box, Button, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import { deleteTestimonial, getTestimonials } from '../Service/api';

const Heading = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 20px;
`;

const MobHeading = styled(Box)`
  display: flex;
  height: 20px;
  justify-content: center;
  flex-direction: column;
  align-items: start;
`;

const Content = styled(Box)`
  border: 1px solid rgba(0, 0, 0, 0.2);
  margin-top: 1vh;

  @media (max-width: 767px) {
    margin-top: 1vh;
    padding: 10px; /* Adjust padding for better mobile layout */
  }
`;

const TestimonialHeading = styled(Box)`
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding: 1%;
  display: flex;
  flex-direction: column;
  justify-content: start;
`;

const MainHeading = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 1%;
  padding-right: 1%;
  min-height: 2vh;
`;

const Written = styled(Box)`
  min-height: 1.5vh;
  width: 10vw;
  display: flex;
  word-wrap: break-word;
  overflow: hidden;
  justify-content: start;
`;

const UL = styled.ul`
  margin: 0;
  padding: 0;
  margin-left: 1%;
`;

const MainContent = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 1%;
  margin-left: 1%;
  padding-left: 0;
  margin-left: 0;
  height: 5vh;
  background-color: #e9e9ef40;
  border-top: 1px solid rgba(0, 0, 0, 0.2);

  &:nth-of-type(2) {
    background-color: white;
  }

  @media (max-width: 767px) {
    height: auto;
    padding: 5px;
    margin-bottom: 5px;
  }
`;

const ResponsiveButton = styled(Button)`
  color: white;
  width: 10vw;
  height: 4vh;
  background-color: #74788d;
  padding: 24px;
  
  

  @media (max-width: 767px) {
    width: 35vw;
    height: 5vh;
    font-size: 10px;
  }
`;

const Delete = styled(Box)`
  height: 28px;
  width: 2vw;
  border: 1px solid rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #74788D;
  cursor: pointer;

  @media (max-width: 767px) {
    height: 30px;
    width: 30px;
    font-size: 18px;
  }
`;

const Testimonials = () => {
  const [isMobile, setIsMobile] = React.useState(window.innerWidth <= 767);
  const [testimonial, setTestimonial] = useState();

  React.useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
    };

    window.addEventListener('resize', handleResize);

    // Clean up the event listener
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const deleteT = async (data) => {
    try {
      let result = await deleteTestimonial(data);
      setTestimonial(result.data)
      console.log(result);
    } catch (error) {
      console.log(error);
    }
  } 


  useEffect(() => {
      const random = () => getTestimonials().then(function(result) {
        setTestimonial(result.data)
      })
      random();
  }, [])

  const navigate = useNavigate();

  if (typeof testimonial === 'undefined') {
    return (
      <h1>Loading...</h1>
    )
  }

  return (
    <>
      <Heading>
        <h3>Services</h3>
        <Typography>
          <span style={{ fontWeight: 600 }}>Home</span> &gt;{' '}
          <span style={{ fontWeight: 300 }}>Services</span>
        </Typography>
      </Heading>
      
      <Content>
        <TestimonialHeading>
          <h4 style={{ textAlign: 'start' }}>Testimonials</h4>
          <ResponsiveButton onClick={() => navigate('/addtestimonial')}>Add Testimonials</ResponsiveButton>
        </TestimonialHeading>
        <MainHeading>
          <Written><h4>Id</h4></Written>
          <Written><h4>Name</h4></Written>
          <Written><h4>اسم</h4></Written>
          <Written><h4>Action</h4></Written>
          <Written><h4></h4></Written>
        </MainHeading>
        <UL>
          {
            testimonial.map((result, key) => (
              <MainContent key={key}>
                <Written>{key+1}</Written>
                <Written>{result.name}</Written>
                <Written>{result.arname}</Written>
                <Written><Delete><DeleteIcon onClick={() => deleteT(result)}/></Delete></Written>
                <Written></Written>
              </MainContent>
            ))
          }
        </UL>
      </Content>
    </>
  )
}

export default Testimonials;
