import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { Box, Button, Typography } from '@mui/material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill's CSS
import { getAboutUs, updateAbout } from '../Service/api';
import DOMPurify from 'dompurify';
import axios from 'axios'

const Heading = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 20px;
`;

const ArabicWrapper = styled.div`
  direction: rtl;
`;

const ArabicHeading = styled.h4`
  font-family: 'Arabic Font', Arial, sans-serif;
  text-align: right;
`;

const EnglishHeading = styled.h4`
  display: flex;
  justify-content: start;
`;

const RichTextEditorWrapper = styled(Box)`
  margin-top: 20px;
  .ql-container {
    border: 1px solid #ccc;
    min-height: 200px;
    text-align: right; /* Align the Arabic text inside the editor to the right */
  }
`;

const Content = styled(Box)`
  border: 1px solid rgba(0, 0, 0, 0.2);
  margin: 1vw;
  padding: 1vw;

  @media (max-width: 767px) {
    margin: 1vh;
    padding: 1vh;
  }
`;

const Btn = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: start;
  margin-top: 1vh;
  margin-bottom: 1vh;

  @media (max-width: 767px) {
    margin-top: 2vh;
    margin-bottom: 2vh;
  }
`;


const MobHeading = styled(Box)`
  display: flex;
  height: 20px
  justify-content: center;
  flex-direction: column;
  align-items: start;
`



const AboutUs = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const [englishContent, setEnglishContent] = useState('');
  const [arabicContent, setArabicContent] = useState('');
  const [data, setData] = useState({})

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
    };

    window.addEventListener('resize', handleResize);

    // Clean up the event listener
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const onInputChange = (content, name) => {
    if (name === 'about') {
      setEnglishContent(content);
    } else if (name === 'arabout') {
      setArabicContent(content);
    }
  };

  const signupUser = async () => {
    try {
      const requestData = {
        about: englishContent,
        arabout: DOMPurify.sanitize(arabicContent),
      };
      let res = await updateAbout(requestData);
      if (res) {
        setEnglishContent(res.data.about)
        setArabicContent(res.data.arabout)
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    const random = () => getAboutUs().then(function(result) {
        console.log(result);
        setEnglishContent(result.about)
        setArabicContent(result.arabout)
    })

  random();
  }, []);

  // if (typeof englishContent === 'undefined' || typeof arabicContent === 'undefined') {
  //   return(
  //     <h1>Loading...</h1>
  //   )
  // }

  return (
    <>
      {!isMobile && <Heading>
        <h3>About Us</h3>
        <Typography>
          <span style={{ fontWeight: 600 }}>Home</span> &gt;{' '}
          <span style={{ fontWeight: 300 }}>About Us</span>
        </Typography>
      </Heading>}
      {isMobile && <MobHeading>
        <h3>About Us</h3>
        <Typography>
          <span style={{ fontWeight: 600 }}>Home</span> &gt;{' '}
          <span style={{ fontWeight: 300 }}>About Us</span>
        </Typography>
      </MobHeading>}
      <Content component='form' noValidate >
        <RichTextEditorWrapper>
          <EnglishHeading>About Us</EnglishHeading>
          <ReactQuill
            value={englishContent}
            onChange={(content) => onInputChange(content, 'about')}
            name='about'
          />
        </RichTextEditorWrapper>
        {isMobile && (
          <>
            <RichTextEditorWrapper>
              <ArabicWrapper>
                <ArabicHeading>الصفحة الأولى</ArabicHeading>
                <ReactQuill
                  value={arabicContent}
                  onChange={(content) => onInputChange(content, 'arabout')}
                  name='arabout'
                />
              </ArabicWrapper>
            </RichTextEditorWrapper>
          </>
        )}
        {!isMobile && (
          <RichTextEditorWrapper>
            <ArabicWrapper>
              <ArabicWrapper>
                <ArabicHeading>الصفحة الأولى</ArabicHeading>
                <ReactQuill
                  value={arabicContent}
                  onChange={(content) => onInputChange(content, 'arabout')}
                  name='arabout'
                />
              </ArabicWrapper>
            </ArabicWrapper>
          </RichTextEditorWrapper>
        )}
        <Btn>
          <Button onClick={() => signupUser()} style={{ color: 'white', width: '2vw', backgroundColor: '#2ab57d' }}>
            Submit
          </Button>
        </Btn>
      </Content>
    </>
  );
};

export default AboutUs;
