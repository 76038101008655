import React, { useContext, useEffect, useState } from 'react'
import {
    Box, Button, Input, TextField, Typography
} from '@mui/material'
import styled from '@emotion/styled'
import { useNavigate } from 'react-router-dom'
import AuthContext from '../contextApi/AuthContext'
import {NotificationContainer, NotificationManager} from 'react-notifications';
import { toast } from 'react-toastify'

const Main = styled(Box)`
    height: 100vh;
    width: 100vw;
    overflow-x: hidden;
    display: flex;
`

const LoginInfo = styled(Box)`
    height: 100vh;
    width: 30vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`

const Header = styled(Box)`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px;
`

const Image = styled(Box)`
    height: 28px;
    margin: 20px;
`

const MobImage = styled(Box)`
    height: 28px;
`

const MainForm = styled(Box)`
    height: 50vh;
    justify-content: space-between;
    padding: 50px;
`


const Label = styled(Box)`
    display: flex;
    justify-content: start;
`

const Form = styled(Box)`
    width: 100%;
    height: 35vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between
`

const Inputtag = styled(Box)`
    display: flex;
    justify-content: start;
`

const Btn = styled(Button)`
    display: flex;
    justify-content: center;
    align-item: center;
    background-color: #5156be;
    color: #fff;
    margin-top: 1%;
`
 const Footer = styled(Box)`
    display: flex;
    justify-content: center;
    height: 10vh;
 `

 const Design = styled(Box)`
    width: 70%;
    background-color: #4A009F;
    height: 100%;
 `

 const MobileScreen = styled(Box)`
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
 `

 const MobileHeader = styled(Box)`
    height: 20vh;
    display: flex;
    justify-content: center;
    align-items: center;
 `

 const MobileForm = styled(Box)`
    height: 60vh;
    justify-content: space-between;
 `

 const MobForm = styled(Box)`
    width: 100%;
    height: 45vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around
`


const initialState = {
    email: '',
    password: ''
}

const Login = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 767)
    const [data, setData] = useState(initialState)
    const { login } = useContext(AuthContext)

    const onValueChange = (e) => {
        setData({...data, [e.target.name]: e.target.value});
    }


    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            await login(data);
            navigate(`/dashboard/${localStorage.getItem('userId')}`); 
        } catch (error) {// Show error message
            toast.error('Login Failed!');
            console.log(error);
        }
    };
    useEffect(() => {
        const handleResize = () => {
          setIsMobile(window.innerWidth <= 600);
        };
    
        window.addEventListener('resize', handleResize);
    
        // Clean up the event listener
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);
    const navigate = useNavigate()
  return (
    <>
        {!isMobile && 
        <Main>
            <LoginInfo>
                <Header>
                    <Image component="img" src='/img/logoshort.png' />
                    <Box component="h2">Amani Alotaibi</Box>
                </Header>
                <MainForm>
                    <Box>
                        <Box component="h2">Welcome Back !</Box>
                        <Typography style={{ opacity: "0.5"}}>Sign in to continue to Amani Alotaibi Admin.</Typography>
                    </Box>
                    <Form component='form' onSubmit={handleSubmit} noValidate>
                        <Box>
                            <Label component='h4' style={{ opacity: '0.5', fontWeight: '700'}} >Username</Label>
                            <Inputtag><TextField style={{ width: '100%', height: '30px'}} type='email' onChange={(e) => onValueChange(e)} name='email' placeholder='Enter username'/></Inputtag>
                        </Box>
                        <Box>
                            <Label component='h4' style={{ opacity: '0.5', fontWeight: '700'}} >Password</Label>
                            <Inputtag><TextField style={{ width: '100%', height: '30px'}} type='password' onChange={(e) => onValueChange(e)} name='password' placeholder='Enter password'/></Inputtag>
                        </Box>
                        <Btn
                            sx={{
                                ":hover": {
                                bgcolor: "#310077",
                                color: "white"
                                }
                            }}
                            type="submit" // Change the button type to "submit" to trigger handleSubmit
                            >
                            Log In
                        </Btn>
                    </Form>
                </MainForm>
                <Footer>
                    <Typography style={{opacity: 0.5}}>@2023 Amani Alotaibi. Crafted with <span style={{textDecoration: 'none'}}>❤️</span> by AN International</Typography>
                </Footer>
            </LoginInfo>
            <Design></Design>
        </Main>}
        {isMobile && 
            <MobileScreen>
                <MobileHeader>
                    <MobImage component="img" src='/img/logoshort.png' />
                    <Box component="h2">Amani Alotaibi</Box>
                </MobileHeader>
                <MobileForm>
                    <Box>
                        <Box component="h2">Welcome Back !</Box>
                        <Typography style={{ opacity: "0.5"}}>Sign in to continue to Amani Alotaibi Admin.</Typography>
                    </Box>
                    <MobForm component='form' onSubmit={handleSubmit} noValidate>
                        <Box>
                            <Label component='h4' style={{ opacity: '0.5', fontWeight: '700'}} >Username</Label>
                            <Inputtag><TextField style={{ width: '100%', height: '30px'}} type='email' onChange={(e) => onValueChange(e)} name='email' placeholder='Enter username'/></Inputtag>
                        </Box>
                        <Box>
                            <Label component='h4' style={{ opacity: '0.5', fontWeight: '700'}} >Password</Label>
                            <Inputtag><TextField style={{ width: '100%', height: '30px'}} type='password' onChange={(e) => onValueChange(e)} name='password' placeholder='Enter password'/></Inputtag>
                        </Box>
                        <Btn
                            sx={{
                                ":hover": {
                                bgcolor: "#310077",
                                color: "white"
                                }
                            }}
                            type="submit" // Change the button type to "submit" to trigger handleSubmit
                            >
                            Log In
                        </Btn>
                    </MobForm>
                </MobileForm>
                <Footer>
                    <Typography style={{opacity: 0.5}}>@2023 Amani Alotaibi. Crafted with <span style={{opacity: 1}}>❤️</span> by AN International</Typography>
                </Footer>
            </MobileScreen>}
    </>
  )
}

export default Login
