import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { Box, Button, TextField, Typography } from '@mui/material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill's CSS
import { createTestimonials } from '../Service/api';
import { toast } from 'react-toastify';
import DOMPurify from 'dompurify';

const Heading = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 20px;
`;

const ArabicWrapper = styled.div`
  direction: rtl;
`;

const ArabicHeading = styled.h4`
  font-family: 'Arabic Font', Arial, sans-serif;
  text-align: right;
`;

const EnglishHeading = styled.h4`
  display: flex;
  justify-content: start;
`;

const RichTextEditorWrapper = styled(Box)`
  margin-top: 20px;
  .ql-container {
    border: 1px solid #ccc;
    min-height: 200px;
    text-align: right; /* Align the Arabic text inside the editor to the right */
  }
`;

const Content = styled(Box)`
  border: 1px solid rgba(0, 0, 0, 0.2);
  margin: 1vw;
  padding: 1vw;

  @media (max-width: 767px) {
    margin: 1vh;
    padding: 1vh;
  }
`;

const Btn = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: start;
  margin-top: 1vh;
  margin-bottom: 1vh;

  @media (max-width: 767px) {
    margin-top: 2vh;
    margin-bottom: 2vh;
  }
`;



const MobHeading = styled(Box)`
  display: flex;
  height: 20px
  justify-content: center;
  flex-direction: column;
  align-items: start;
`


const AddTestimonials = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const [englishContent, setEnglishContent] = useState('');
  const [arabicContent, setArabicContent] = useState('');
  const [name, setName] = useState('')
  const [arname, setArname] = useState('')


const onInputChange = (content, name) => {
  if (name === 'name') {
    setName(content);
  } else if (name === 'arname') {
    setArname(DOMPurify.sanitize(content));
  }else if (name === 'comment') {
    setEnglishContent(DOMPurify.sanitize(content));
  }else if (DOMPurify.sanitize(name === 'arcomment')){
    setArabicContent(content);
  }
};

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
    };

    window.addEventListener('resize', handleResize);

    // Clean up the event listener
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const signupUser = async () => {
    try {
      const data = {
        name: name,
        arname: arname,
        comment: englishContent,
        arcomment: arabicContent
      }
      console.log(data);
      let res = await createTestimonials(data);
      if (res.status === 200) {
        toast.success('Posted!')
      }else{
        toast.error('Not Posted')
      }
    } catch (error) {
      toast.error('Not Posted')
      console.log(error);
    }
  }

  return (
    <>
      {!isMobile && <Heading>
        <h3>Add Testimonial</h3>
        <Typography>
          <span style={{ fontWeight: 600 }}>Testimonial</span> &gt;{' '}
          <span style={{ fontWeight: 300 }}>Add Testimonial</span>
        </Typography>
      </Heading>}
      {isMobile && <MobHeading>
        <h3>Add Testimonial</h3>
        <Typography>
          <span style={{ fontWeight: 600 }}>Testimonial</span> &gt;{' '}
          <span style={{ fontWeight: 300 }}>Add Testimonial</span>
        </Typography>
      </MobHeading>}
      <Content component='form' noValidate>
        <RichTextEditorWrapper>
          <EnglishHeading >Name</EnglishHeading>
          <TextField style={{width: '100%'}} value={name} name='name' onChange={(e) => onInputChange(e.target.value, 'name')}/>
          <ArabicWrapper>
            <ArabicHeading>اسم</ArabicHeading>
            <TextField style={{width: '100%'}} value={arname} name='arname' onChange={(e) => onInputChange(e.target.value, 'arname')}/>
          </ArabicWrapper>
          <EnglishHeading >Comment</EnglishHeading>
          <ReactQuill
            value={englishContent}
            name='comment'
            onChange={(content) => onInputChange(content, 'comment')}
          />
        </RichTextEditorWrapper>
        {isMobile && (
          <>
            <RichTextEditorWrapper>
              <ArabicHeading>تعليق</ArabicHeading>
              <ReactQuill
                name='arcomment'
                value={arabicContent}
                onChange={(content) => onInputChange(content, 'arcomment')}
              />
            </RichTextEditorWrapper>
          </>
        )}
        {!isMobile && (
          <RichTextEditorWrapper>
            <ArabicWrapper>
              <ArabicHeading>تعليق</ArabicHeading>
              <ReactQuill
                name='arcomment'
                value={arabicContent}
                onChange={(content) => onInputChange(content, 'arcomment')}
              />
            </ArabicWrapper>
          </RichTextEditorWrapper>
        )}
        <Btn>
          <Button style={{ color: 'white', width: '2vw', backgroundColor: '#2ab57d' }} onClick={() => signupUser()}>
            Submit
          </Button>
        </Btn>
      </Content>
    </>
  );
};

export default AddTestimonials;
