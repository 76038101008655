import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ListItem from '@mui/material/ListItem';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Dashboard from './Dashboard';
import { Link, Outlet, Route, Routes, useNavigate } from 'react-router-dom';
import { MenuItem, Select } from '@mui/material';

const drawerWidth = 300;

const Header = styled(Box)`
  display: flex;
  justify-content: start;
  align-items: center;
`;

const Image = styled(Box)`
  height: 38px;
  margin-right: 10px;
`;

const MenuHeading = styled(Box)`
  display: flex;
  justify-content: start;
  padding-left: 20px;
`;

const Item = styled(ListItem)`
  padding-left: 100px;
  display: flex;
  justify-content: start;
  cursor: pointer;
`;

const Navbar = styled(Toolbar)`
  background-color: #fff;
  height: 80px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: none;
  color: #757575;
  @media (max-width: 767px) {
    display: none; /* Hide the desktop navbar on mobile */
  }
`;

const MobileNavbar = styled(Toolbar)`
  background-color: #fff;
  height: 10vh;
  width: 100vw;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: none;
  color: #757575;
  display: flex;
  justify-content: space-between;
  @media (min-width: 768px) {
    display: none; /* Hide the mobile navbar on desktop */
  }
`;

const Profile = styled(Box)`
  height: 80px;
  width: 150px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 0;
  right: 30px;
  background-color: #f9f9fb;
`;

const MobileProfile = styled(Box)`
  height: 10vh;
  width: 20vw;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background-color: #f9f9fb;
  @media (min-width: 768px) {
    display: none; /* Hide the mobile profile on desktop */
  }
`;

const SelectFunc = styled(Select)`
  border: none;
`

const Main = styled(Box)(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: open ? `${drawerWidth}px` : '0', // Adjust this value to match the width of the Drawer
  marginTop: '80px', // Add extra padding for the fixed Navbar
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const MobileMain = styled(Box)(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: open ? `${drawerWidth}px` : '0', // Adjust this value to match the width of the Drawer
  paddingTop: '12vh', // Add extra padding for the fixed Navbar
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'space-between',
}));

export default function Layout() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [mobopen, setMobOpen] = React.useState(false);
  const [isMobile, setIsMobile] = React.useState(window.innerWidth <= 767);

  React.useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
    };

    window.addEventListener('resize', handleResize);

    // Clean up the event listener
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleDrawerOpen = () => {
    setOpen(true);
    setMobOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    setMobOpen(false);
  };

  const handleMenuClick = () => {
    if (isMobile) {
      handleDrawerClose(); // Close the Drawer on mobile when a menu item is clicked
    }
  };

  const navigate = useNavigate();

  return (
    <>
      <CssBaseline />
      <AppBar elevation={0} position="fixed" open={open}>
        <Navbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>
          <Profile>
            <AccountCircleIcon fontSize="large" />
            Admin.
            <SelectFunc>
              <MenuItem onClick={() => {
                localStorage.clear();
                navigate('/login')
              }}>Logout</MenuItem>
            </SelectFunc>
          </Profile>
        </Navbar>
        <MobileNavbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>
          <MobileProfile>
            <AccountCircleIcon fontSize="large" />
            <KeyboardArrowDownIcon />
          </MobileProfile>
        </MobileNavbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant={isMobile ? 'temporary' : 'persistent'}
        anchor="left"
        open={open}
        onClose={handleDrawerClose}
      >
        <DrawerHeader>
          <Header>
            <Image component="img" src="/img/logoshort.png" />
            <Box component="h2">Amani</Box>
          </Header>
          <IconButton onClick={handleDrawerClose}>
            <MenuIcon />
          </IconButton>
        </DrawerHeader>
        <MenuHeading>Menu</MenuHeading>
        <List>
          <Item onClick={() => {navigate(`/dashboard/${localStorage.getItem('userId')}`); setMobOpen(false)}}>Dashboard</Item>
          <Item onClick={() => {navigate('/aboutus'); setMobOpen(false)}}>About Us</Item>
          <Item onClick={() => {navigate('/testimonials'); setMobOpen(false)}}>Testimonial</Item>
          <Item onClick={() => {navigate(`/content/${localStorage.getItem('userId')}`); setMobOpen(false)}}>Content</Item>
          <Item onClick={() => {navigate('/adduser'); setMobOpen(false)}}>Add User</Item>
          <Item onClick={() => {navigate('/appointment'); setMobOpen(false)}}>Appointment</Item>
          <Item onClick={() => {navigate('/podcast'); setMobOpen(false)}}>Podcast</Item>
          <Item onClick={() => {navigate('/subscribed'); setMobOpen(false)}}>Subscribed</Item>
          <Item onClick={() => {navigate('/sendemail'); setMobOpen(false)}}>Send Email</Item>
          <Item onClick={() => {navigate('/addholiday'); setMobOpen(false)}}>Add Holiday</Item>
        </List>
      </Drawer>
      {isMobile ? (
        <MobileMain>
          <Outlet />
        </MobileMain>
      ) : (
        <Main open={open}>
          <Outlet />
        </Main>
      )}
    </>
  );
}
