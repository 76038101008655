import styled from '@emotion/styled'
import { Box, Button, Input } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { addH, deleteDates, fetchHolidays } from '../Service/api'
import DeleteIcon from '@mui/icons-material/Delete';
import { toast } from 'react-toastify';

const Main = styled(Box)`
    display: flex;
    justify-content: center;
    flex-direction: column;
    aling-items: center;
`

const DateBox = styled(Box)`
    display: flex;
    justify-content: center;
    flex-direction: column;
    aling-items: center;
`

const loginInitialValues = {
    start: '',
    end: ''
}

const UL = styled.ul`
  margin: 0;
  padding: 0;
  margin-top: 1%;
  width: 30%;
`;

const EditContent = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 1%;
  margin-left: 1%;
  padding-left: 0;
  margin-left: 0;
  height: 5vh;
  background-color: #e9e9ef40;
  border-top: 1px solid rgba(0, 0, 0, 0.2);

  &:nth-of-type(2) {
    background-color: white;
  }

  @media (max-width: 767px) {
    height: auto;
    padding: 5px;
    margin-bottom: 5px;
  }
`;

const Written = styled(Box)`
  min-height: 1.5vh;
  width: 10vw;
  display: flex;
  word-wrap: break-word;
  overflow: hidden;
  justify-content: start;
`;

const Delete = styled(Box)`
  height: 28px;
  width: 2vw;
  border: 1px solid rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #74788D;
  cursor: pointer;

  @media (max-width: 767px) {
    height: 30px;
    width: 30px;
    font-size: 18px;
  }
`;


const AddHoliday = () => {
    const [login, setLogin] = useState(loginInitialValues)
    const [content, setContent] = useState()

    useEffect(() => {
        const random = () => fetchHolidays().then(function(result) {
          setContent(result.data)
          console.log(result.data);
        })
        random();
      }, [])

    const onValueChange = (e) => {
        setLogin({...login, [e.target.name]: e.target.value});
    }
    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        // console.log({
        //   email: data.get('email'),
        //   password: data.get('password'),
        // });
    };

    const formatDate = (dateString) => {
        const dateObject = new Date(dateString);
        const day = dateObject.getDate();
        const month = dateObject.getMonth() + 1;
        const year = dateObject.getFullYear();
        return `${day}/${month}/${year}`;
      };

    const loginUser = async () => {
        let response = await addH(login);
        if (response) {
            setContent(response.data)
            toast.success('Posted!')
        }else{
            toast.error('Error in posting')
        }
      }

    const deleteDate = async(data) => {
        let response = await deleteDates(data);
        if (response) {
            setContent(response.data)
            toast.success('Posted!')
        }else{
            toast.error('Error in posting')
        }
    }

  return (
    <Main>
      <DateBox component='form' onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <h3 style={{textAlign: 'start'}}>Start Date:</h3>
        <Input type='date' style={{width: '20%'}} name='start' onChange={(e) => onValueChange(e)}/>
        <h3 style={{textAlign: 'start'}}>End Date:</h3>
        <Input type='date' style={{width: '20%'}} name='end' onChange={(e) => onValueChange(e)}/>
        <Button style={{width: '5%'}} onClick={() => loginUser()}>Submit</Button>
      </DateBox>

      <UL>
        Scheduled Holidays:-
        {content && content.map((cont, key) => (
            <EditContent key={key}>
              <Written>{key+1}</Written>
              <Written>{formatDate(cont.start)}</Written>
              <Written>{formatDate(cont.end)}</Written>
              <Written><Delete onClick={() => deleteDate(cont)}><DeleteIcon /></Delete></Written>
            </EditContent>
          ))}
        </UL>
    </Main>
  )
}

export default AddHoliday
