import React, { useEffect, useState } from 'react'
import Layout from './Layout'
import { Box, Typography } from '@mui/material'
import styled from '@emotion/styled'
import { Padding } from '@mui/icons-material'
import { fetchAppointments, getSubscribed, getUsers } from '../Service/api'

const Heading = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 20px;
`

const MobHeading = styled(Box)`
  display: flex;
  height: 20px
  justify-content: center;
  flex-direction: column;
  align-items: start;
`

const Body = styled(Box)`
  margin-top: 3vh;
  display: flex;
  flex-wrap: wrap;
`

const MobBody = styled(Box)`
  margin-top: 3vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const Container = styled(Box)`
  width: 17vw;
  height: 15vh;
  padding: 2vh;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: start;
  margin-left: 2vw;
  margin-bottom: 2vh;
`

const MobContainer = styled(Box)`
  width: 80vw;
  height: 15vh;
  padding: 2vh;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: start;
  margin-left: 2vw;
  margin-bottom: 2vh;
`

const Dashboard = () => {
  const [isMobile, setIsMobile] = React.useState(window.innerWidth <= 767);
  const [subs, setSubs] = useState(0);
  const [use, setUse] = useState(0)
  const [appoint, setAppoint] = useState(0)

  React.useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
    };

    window.addEventListener('resize', handleResize);

    // Clean up the event listener
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const random = () => getSubscribed().then(function(result) {
      console.log(result);
      setSubs(result.data.length)
    })

    const user = () => getUsers().then(function(result) {
      console.log(result);
      setUse(result.data.length)
    })

    const appointment = () => fetchAppointments().then(function(result) {
      console.log(result);
      setAppoint(result.data.length)
    })

    random()
    user()
    appointment()
  })

  return (
    <>
        {!isMobile && <Box>
        <Heading>
          <h3>Dashboard</h3>
          <Typography>
            <span style={{fontWeight: 600}}>Dashboard</span> &gt; <span style={{fontWeight: 300}}>Dashboard</span>
          </Typography>
        </Heading>
        <Body>
          <Container>
            <Typography style={{fontWeight: 300}}>Total User</Typography>
            <h3>{use}</h3>
          </Container>
          <Container>
            <Typography style={{fontWeight: 300}}>Total Subscription</Typography>
            <h3>{subs}</h3>
          </Container>
          <Container>
            <Typography style={{fontWeight: 300}}>Today's Appointment</Typography>
            <h3>{appoint}</h3>
          </Container>
          <Container>
            <Typography style={{fontWeight: 300}}>Today's Sales</Typography>
            <h3>0</h3>
          </Container>
          <Container>
            <Typography style={{fontWeight: 300}}>Upcoming Appointments</Typography>
            <h3>0</h3>
          </Container>
        </Body>
    </Box>}
    {isMobile && <Box>
        <MobHeading>
          <h3>Dashboard</h3>
          <Typography>
            <span style={{fontWeight: 600}}>Dashboard</span> &gt; <span style={{fontWeight: 300}}>Dashboard</span>
          </Typography>
        </MobHeading>
        <MobBody>
          <MobContainer>
            <Typography style={{fontWeight: 300}}>Total User</Typography>
            <h3>0</h3>
          </MobContainer>
          <MobContainer>
            <Typography style={{fontWeight: 300}}>Total Subscription</Typography>
            <h3>0</h3>
          </MobContainer>
          <MobContainer>
            <Typography style={{fontWeight: 300}}>Today's Appointment</Typography>
            <h3>0</h3>
          </MobContainer>
          <MobContainer>
            <Typography style={{fontWeight: 300}}>Today's Sales</Typography>
            <h3>0</h3>
          </MobContainer>
          <MobContainer>
            <Typography style={{fontWeight: 300}}>Upcoming Appointments</Typography>
            <h3>0</h3>
          </MobContainer>
        </MobBody>
    </Box>}
    </>
  )
}

export default Dashboard
