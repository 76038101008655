import axios from 'axios'
const URL = 'http://localhost:3000'

// About Page API Links

export const getAboutUs = async () => {
    try {
        const config = {
          headers: {
              Authorization : `Bearer ${JSON.parse(localStorage.getItem('token'))}`
          }
      }
        const response = await axios.get(`${URL}/api/user/get-aboutus`, config);
        const data = response.data;
        console.log(data);
        return data;
      } catch (error) {
        console.log('Error', error);
      }
}


export const updateAbout = async (data) => {
    try {
        const config = {
            headers: {
                Authorization : `Bearer ${JSON.parse(localStorage.getItem('token'))}`
            }
        }
        return await axios.patch(`${URL}/api/user/create-about`, data, config);
    } catch (error) {
        console.log('Error', error.response.data);
    }
}

// Testimonials

export const createTestimonials = async (data) => {
    try {
        const config = {
            headers: {
                Authorization : `Bearer ${JSON.parse(localStorage.getItem('token'))}`
            }
        }
        return await axios.post(`${URL}/api/user/create-testimonial`, data, config);
    } catch (error) {
        console.log('Error', error.response.data);
    }
}

export const getTestimonials = async () => {
    try {
        const config = {
            headers: {
                Authorization : `Bearer ${JSON.parse(localStorage.getItem('token'))}`
            }
        }

        return await axios.get(`${URL}/api/user/get-testimonial`, config);
    } catch (error) {
        console.log('Error', error.response.data);
    }
}

export const deleteTestimonial = async(data) => {
    try {
        const config = {
            headers: {
                Authorization : `Bearer ${JSON.parse(localStorage.getItem('token'))}`
            }
        }

        return await axios.post(`${URL}/api/user/delete-testimonial`, data, config);
    } catch (error) {
        console.log('Error', error.response.data);
    }
}


// Content

export const createContent = async(formData, paramsone) => {
    try {
        const config = {
            headers: {
                Authorization : `Bearer ${JSON.parse(localStorage.getItem('token'))}`,
                'Content-Type': 'multipart/form-data',
            }
        }

        return await axios.post(`${URL}/api/content/add-content/${paramsone}`, formData, config);
    } catch (error) {
        console.log(error);
    }
}

export const getContent = async () => {
    try {
        const config = {
            headers: {
                Authorization : `Bearer ${JSON.parse(localStorage.getItem('token'))}`
            }
        }

        return await axios.get(`${URL}/api/content/get-content`, config);
    } catch (error) {
        console.log('Error', error.response.data);
    }
}

export const getSingleProduct = async (data) => {
    try {
        const config = {
            headers: {
                Authorization : `Bearer ${JSON.parse(localStorage.getItem('token'))}`
            }
        }

        return await axios.get(`${URL}/api/content/single-product/${data}`, config);
    } catch (error) {
        console.log('Error', error.response.data);
    }
}

export const updateSingleProduct = async (data, params) => {
    try {
        const config = {
            headers: {
                Authorization : `Bearer ${JSON.parse(localStorage.getItem('token'))}`
            }
        }

        return await axios.patch(`${URL}/api/content/update/${params}`, data, config);
    } catch (error) {
        console.log('Error', error.response.data);
    }
}

export const deleteSingleProduct = async (data) => {
    try {
        const config = {
            headers: {
                Authorization : `Bearer ${JSON.parse(localStorage.getItem('token'))}`
            }
        }

        return await axios.post(`${URL}/api/content/delete-content`, data, config);
    } catch (error) {
        console.log('Error', error.response.data);
    }
}


export const addVideo = async(formData, paramsone) => {
    try {
        const config = {
            headers: {
                Authorization : `Bearer ${JSON.parse(localStorage.getItem('token'))}`,
                'Content-Type': 'multipart/form-data',
            }
        }

        return await axios.post(`${URL}/api/content/create-video/${paramsone}`, formData, config);
    } catch (error) {
        console.log(error);
    }
}

export const getVideos = async(data) => {
    try {
        const config = {
            headers: {
                Authorization : `Bearer ${JSON.parse(localStorage.getItem('token'))}`
            }
        }

        return await axios.get(`${URL}/api/content/get-videos/${data}`, config);
    } catch (error) {
        console.log('Error', error.response.data);
    }
}

export const deleteV = async(data, params) => {
    try {
        const config = {
            headers: {
                Authorization : `Bearer ${JSON.parse(localStorage.getItem('token'))}`
            }
        }

        return await axios.post(`${URL}/api/content/delete/${params}`, data, config)
    } catch (error) {
        
    }
}

export const addLink = async(formData, paramsone) => {
    try {
        const config = {
            headers: {
                Authorization : `Bearer ${JSON.parse(localStorage.getItem('token'))}`,
            }
        }

        return await axios.post(`${URL}/api/content/create-link/${paramsone}`, formData, config);
    } catch (error) {
        console.log(error);
    }
}

export const getLinks = async(data) => {
    try {
        const config = {
            headers: {
                Authorization : `Bearer ${JSON.parse(localStorage.getItem('token'))}`
            }
        }

        return await axios.get(`${URL}/api/content/get-links/${data}`, config);
    } catch (error) {
        console.log('Error', error.response.data);
    }
}

export const deleteL = async(data, params) => {
    try {
        const config = {
            headers: {
                Authorization : `Bearer ${JSON.parse(localStorage.getItem('token'))}`
            }
        }

        return await axios.post(`${URL}/api/content/delete-link/${params}`, data, config)
    } catch (error) {
        console.log(error);
    }
}


// Add User

export const addUser = async(data) => {
    try {

        return await axios.post(`${URL}/api/user/register-user`, data)
    } catch (error) {
        console.log(error);
    }
}

export const fetchUser = async() => {
    try {
        const config = {
            headers: {
                Authorization : `Bearer ${JSON.parse(localStorage.getItem('token'))}`
            }
        }
        return await axios.get(`${URL}/api/user/fetch-user`, config)
    } catch (error) {
        console.log(error);
    }
}

export const deleteU = async(data) => {
    console.log(data);
    try {
        const config = {
            headers: {
                Authorization : `Bearer ${JSON.parse(localStorage.getItem('token'))}`
            }
        }
        return await axios.post(`${URL}/api/user/delete-user`, data, config)
    } catch (error) {
        console.log(error);
    }
}


// Appointment 

export const createAppointment = async(data) => {
    try {
        const config = {
            headers: {
                Authorization: `Bearer ${JSON.parse(localStorage.getItem('token'))}`
            }
        }

        return await axios.post(`${URL}/api/user/create-appointment`, data, config)
    } catch (error) {
        console.log(error);
    }
}

export const fetchAppointments = async() => {
    try {
        const config = {
            headers: {
                Authorization : `Bearer ${JSON.parse(localStorage.getItem('token'))}`
            }
        }
        return await axios.get(`${URL}/api/user/fetch-appointment`, config)
    } catch (error) {
        console.log(error);
    }
}

// Podcasts

export const createPodcast = async(data) => {
    try {
        const config = {
            headers: {
                Authorization: `Bearer ${JSON.parse(localStorage.getItem('token'))}`
            }
        }

        return await axios.post(`${URL}/api/user/create-podcast`, data, config)
    } catch (error) {
        console.log(error);
    }
}


export const fetchPodcasts = async() => {
    try {
        const config = {
            headers: {
                Authorization : `Bearer ${JSON.parse(localStorage.getItem('token'))}`
            }
        }
        return await axios.get(`${URL}/api/user/get-podcast`, config)
    } catch (error) {
        console.log(error);
    }
}


export const deletePod = async(data) => {
    try {
        const config = {
            headers: {
                Authorization: `Bearer ${JSON.parse(localStorage.getItem('token'))}`
            }
        }

        return await axios.post(`${URL}/api/user/delete-podcast`, data, config)
    } catch (error) {
        console.log(error);
    }
}


export const getSinglePod = async (data) => {
    try {
        const config = {
            headers: {
                Authorization : `Bearer ${JSON.parse(localStorage.getItem('token'))}`
            }
        }

        return await axios.get(`${URL}/api/user/fetch-podcast/${data}`, config);
    } catch (error) {
        console.log('Error', error.response.data);
    }
}


export const updateSinglePod = async (data, params) => {
    try {
        const config = {
            headers: {
                Authorization : `Bearer ${JSON.parse(localStorage.getItem('token'))}`
            }
        }

        return await axios.patch(`${URL}/api/user/updatepod/${params}`, data, config);
    } catch (error) {
        console.log('Error', error.response.data);
    }
}


export const addPodcasts = async(formData, paramsone) => {
    try {
        const config = {
            headers: {
                Authorization : `Bearer ${JSON.parse(localStorage.getItem('token'))}`,
            }
        }

        return await axios.post(`${URL}/api/user/create-audio/${paramsone}`, formData, config);
    } catch (error) {
        console.log(error);
    }
}

export const getAudios = async(data) => {
    try {
        const config = {
            headers: {
                Authorization : `Bearer ${JSON.parse(localStorage.getItem('token'))}`
            }
        }

        return await axios.get(`${URL}/api/user/get-audios/${data}`, config);
    } catch (error) {
        console.log('Error', error.response.data);
    }
}


export const deleteA = async(data, params) => {
    try {
        const config = {
            headers: {
                Authorization : `Bearer ${JSON.parse(localStorage.getItem('token'))}`
            }
        }

        return await axios.post(`${URL}/api/user/delete-audio/${params}`, data, config)
    } catch (error) {
        console.log(error);
    }
}

// Add Holiday


export const addH = async(data) => {
    try {
        const config = {
            headers: {
                Authorization : `Bearer ${JSON.parse(localStorage.getItem('token'))}`
            }
        }

        return await axios.post(`${URL}/api/user/disable-dates`, data, config)
    } catch (error) {
        console.log(error);
    }
}

export const fetchHolidays = async() => {
    try {
        const config = {
            headers: {
                Authorization : `Bearer ${JSON.parse(localStorage.getItem('token'))}`
            }
        }

        return await axios.get(`${URL}/api/user/getholiday`, config)
    } catch (error) {
        console.log(error);
    }
}

export const deleteDates = async(data) => {
    try {
        const config = {
            headers: {
                Authorization : `Bearer ${JSON.parse(localStorage.getItem('token'))}`
            }
        }

        return await axios.post(`${URL}/api/user/delete-dates`, data, config)
    } catch (error) {
        console.log(error);
    }
}


export const getSubscribed = async () => {
    try {
        const config = {
            headers: {
                Authorization : `Bearer ${JSON.parse(localStorage.getItem('token'))}`
            }
        }

        return await axios.get(`${URL}/api/user/get-subscribed`, config)
    } catch (error) {
        console.log(error);
    }
}

// Send Email


export const getUsers = async() => {
    try {
        const config = {
            headers: {
                Authorization : `Bearer ${JSON.parse(localStorage.getItem('token'))}`
            }
        }

        return await axios.get(`${URL}/api/user/get-users`, config)
    } catch (error) {
        
    }
}

export const postEmail = async(data) => {
    console.log(data)
    try {
        const config = {
            headers: {
                Authorization : `Bearer ${JSON.parse(localStorage.getItem('token'))}`
            }
        }

        return await axios.post(`${URL}/api/user/send-email`, data, config)
    } catch (error) {
        
    }
}












