import React, { useEffect, useState } from 'react'
import Layout from './Layout'
import styled from '@emotion/styled';
import { Box, Button, Checkbox, FormControlLabel, Input, MenuItem, Select, TextField, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import VideocamIcon from '@mui/icons-material/Videocam';
import CreateIcon from '@mui/icons-material/Create';
import LinkIcon from '@mui/icons-material/Link';
import { createAppointment, fetchAppointments } from '../Service/api';

const Heading = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 20px;
`;


const MobHeading = styled(Box)`
  display: flex;
  height: 20px;
  justify-content: center;
  flex-direction: column;
  align-items: start;
`;

const MainHeading = styled(Box)`
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding: 1%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: start;
  height: 7vh;
`

const EditHeading = styled(Box)`
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding: 1%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: start;
  height: 7vh;
  @media (max-width: 767px) {
    height: 10vh;
  }
`



const MainContent = styled(Box)`
  border: 1px solid rgba(0, 0, 0, 0.2);
  margin-top: 1vh;
  width: 100%;
  @media (max-width: 767px) {
    margin-top: 1vh;
    padding: 1vh;
    width: 100%;
  }
`;

const ViewContent = styled(Box)`
  border: 1px solid rgba(0, 0, 0, 0.2);
  margin-top: 1vh;
  width: 40%;

  @media (max-width: 767px) {
    margin-top: 1vh;
    padding: 10px; /* Adjust padding for better mobile layout */
    width: 100%;
  }
`;


const MainBox = styled(Box)`
  width: auto;
  display: flex;
  justify-content: space-between;
`

const MobileBox = styled(Box)`
  display: flex;
  flex-direction: column;
`


const ArabicWrapper = styled.div`
  direction: rtl;
`;

const ArabicHeading = styled.h4`
  font-family: 'Arabic Font', Arial, sans-serif;
  text-align: right;
`;

const EnglishHeading = styled.h4`
  display: flex;
  justify-content: start;
`;

const RichTextEditorWrapper = styled(Box)`
  padding: 2%;
  .ql-container {
    border: 1px solid #ccc;
    min-height: 200px;
    text-align: right; /* Align the Arabic text inside the editor to the right */
  }
`;

const Btn = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: start;
  margin-top: 1vh;
  margin-bottom: 1vh;

  @media (max-width: 767px) {
    margin-top: 2vh;
    margin-bottom: 2vh;
  }
`;

const UL = styled.ul`
  margin: 0;
  padding: 0;
  margin-left: 1%;
`;

const EditContent = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 1%;
  margin-left: 1%;
  padding-left: 0;
  margin-left: 0;
  height: 5vh;
  background-color: #e9e9ef40;
  border-top: 1px solid rgba(0, 0, 0, 0.2);

  &:nth-of-type(2) {
    background-color: white;
  }

  @media (max-width: 767px) {
    height: auto;
    padding: 5px;
    margin-bottom: 5px;
  }
`;


const EditContentHeading = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 2%;
  padding-right: 2%;
  min-height: 2vh;
`;

const Written = styled(Box)`
word-wrap: break-word;
  overflow: hidden;
  min-height: 1.5vh;
  width: 10vw;
  display: flex;
  justify-content: start;
`;

const Delete = styled(Box)`
  height: 28px;
  width: 2vw;
  border: 1px solid rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #74788D;
  cursor: pointer;

  @media (max-width: 767px) {
    height: 30px;
    width: 30px;
    font-size: 18px;
  }
`;

const ArabicTextField = styled(TextField)`
  direction: rtl;
`;




const AddAppointment = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    type: '',
    time: '',
    date: '',
  });

  // Function to handle form input change
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  // Function to handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      // Call the createAppointment function to make the API request
      const response = await createAppointment(formData);

    } catch (error) {
      // Handle error if the API call fails
      console.error(error);
    }
  };


  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
    };

    window.addEventListener('resize', handleResize);

    // Clean up the event listener
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  
  return (
    <>
      {!isMobile && 
      <>
      <Heading>
        <h3>Add Appointment</h3>
        <Typography>
          <span style={{ fontWeight: 600 }}>Forms</span> &gt;{' '}
          <span style={{ fontWeight: 300 }}>Add Appointment</span>
        </Typography>
      </Heading>
      <MainBox>
      <MainContent component='form' onSubmit={handleSubmit}>
      <MainHeading>
        Add Appointment
      </MainHeading>
      <RichTextEditorWrapper>
        <EnglishHeading>Name</EnglishHeading>
        <TextField
          style={{ width: '100%' }}
          name="name"
          value={formData.name}
          onChange={handleInputChange}
        />

        <EnglishHeading>Email</EnglishHeading>
        <TextField
          style={{ width: '100%' }}
          name="email"
          value={formData.email}
          onChange={handleInputChange}
        />

        <EnglishHeading>Phone</EnglishHeading>
        <TextField
          style={{ width: '100%' }}
          name="phone"
          value={formData.phone}
          onChange={handleInputChange}
        />

        <EnglishHeading>Type</EnglishHeading>
        <Select
          style={{ width: '100%' }}
          name="type"
          value={formData.type}
          onChange={handleInputChange}
        >
          <MenuItem value="Online">Online</MenuItem>
          <MenuItem value="Offline">Offline</MenuItem>
        </Select>

        <EnglishHeading>Time</EnglishHeading>
        <Select
          style={{ width: '100%' }}
          name="time"
          value={formData.time}
          onChange={handleInputChange}
        >
          <MenuItem value="11:00 AM">11:00 AM</MenuItem>
          <MenuItem value="4:30 PM">4:30 PM</MenuItem>
          <MenuItem value="5:00 PM">5:00 PM</MenuItem>
          <MenuItem value="4:00 PM">4:00 PM</MenuItem>
        </Select>

        <EnglishHeading>Date</EnglishHeading>
        <TextField
          type="date"
          style={{ width: '100%' }}
          name="date"
          value={formData.date}
          onChange={handleInputChange}
        />

        <Btn>
          <Button
            style={{ color: 'white', width: '5vw', backgroundColor: '#2ab57d' }}
            type="submit"
          >
            Submit
          </Button>
        </Btn>
      </RichTextEditorWrapper>
    </MainContent>
      </MainBox>
      </>
      }
      {isMobile && 
      <>
      <Heading>
        <h3>Content</h3>
        <Typography>
          <span style={{ fontWeight: 600 }}>Home</span> &gt;{' '}
          <span style={{ fontWeight: 300 }}>Content</span>
        </Typography>
      </Heading>
      <MobileBox>
      <MainContent component='form' onSubmit={handleSubmit}>
      <MainHeading>
        Add Appointment
      </MainHeading>
      <RichTextEditorWrapper>
        <EnglishHeading>Name</EnglishHeading>
        <TextField
          style={{ width: '100%' }}
          name="name"
          value={formData.name}
          onChange={handleInputChange}
        />

        <EnglishHeading>Email</EnglishHeading>
        <TextField
          style={{ width: '100%' }}
          name="email"
          value={formData.email}
          onChange={handleInputChange}
        />

        <EnglishHeading>Phone</EnglishHeading>
        <TextField
          style={{ width: '100%' }}
          name="phone"
          value={formData.phone}
          onChange={handleInputChange}
        />

        <EnglishHeading>Type</EnglishHeading>
        <Select
          style={{ width: '100%' }}
          name="type"
          value={formData.type}
          onChange={handleInputChange}
        >
          <MenuItem value="Online">Online</MenuItem>
          <MenuItem value="Offline">Offline</MenuItem>
        </Select>

        <EnglishHeading>Time</EnglishHeading>
        <Select
          style={{ width: '100%' }}
          name="time"
          value={formData.time}
          onChange={handleInputChange}
        >
          <MenuItem value="11:00 AM">11:00 AM</MenuItem>
          <MenuItem value="4:30 PM">4:30 PM</MenuItem>
          <MenuItem value="5:00 PM">5:00 PM</MenuItem>
          <MenuItem value="4:00 PM">4:00 PM</MenuItem>
        </Select>

        <EnglishHeading>Date</EnglishHeading>
        <TextField
          type="date"
          style={{ width: '100%' }}
          name="date"
          value={formData.date}
          onChange={handleInputChange}
        />

        <Btn>
          <Button
            style={{ color: 'white', width: '5vw', backgroundColor: '#2ab57d' }}
            type="submit"
          >
            Submit
          </Button>
        </Btn>
      </RichTextEditorWrapper>
    </MainContent>
      </MobileBox>
      </>
      }
    </>
  )
}

export default AddAppointment
